import { showAlert } from "@qogni-technologies/design-system/src/components/base/modal-dialog";
import {
  imageToBase64,
  throttle,
} from "@qogni-technologies/design-system/src/shared/common";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { html, nothing } from "lit";
import { createRef, ref } from "lit/directives/ref.js";
import { repeat } from "lit/directives/repeat.js";
import { ChannelDomain } from "../domain/channel-domain";
import { TimelineDomain } from "../domain/timeline-domain";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  PullToRefreshMixin,
  WidgetEnabledPWAPage,
} from "../shared/pwa-page";
import { resizeImage } from "@qogni-technologies/pwa-utils-library/src/utils/resize-image";
import { msg, str } from "@lit/localize";

const BaseClass = PullToRefreshMixin(OnboardedMixin(
  AuthenticatedMixin(WidgetEnabledPWAPage)
))

export class PageTimeline extends BaseClass {
  #domain;
  #channelDomain;
  #contentEditorRef = createRef();
  #editorFormRef = createRef();
  #createPostRef = createRef();
  #nextCursor;

  #fullList = [];

  constructor() {
    super();
    this.#domain = new TimelineDomain();
    this.#channelDomain = new ChannelDomain();
    this.entries = [];
    this._computedEntries = [];
    this.pagination = undefined;
    this.pinned = undefined;
    this.loading = true;
    this.commentsLoading = false;
    this.commentsPagination = undefined;
    this.commentToPost = null;
    this.showCommentsToPost = null;
    this.postEditorActivated = false;
    this.postAs = null;
    this.myChannels = [];
  }

  static get properties() {
    return {
      entries: { type: Array },
      _computedEntries: { type: Array },
      pinned: { type: Array },
      pagination: { type: Object },
      postEditorActivated: { type: Boolean },
      commentToPost: { type: String },
      showCommentsToPost: { type: String },
      loading: { type: Boolean },
      notReady: { type: Boolean },
      commentsLoading: { type: Boolean },
      comments: { type: Array },
      commentsPagination: { type: Object },
      myChannels: { type: Array },
      postAs: { type: Object },
    };
  }

  get draft() {
    return localStorage.getItem("new-post-draft") || "";
  }
  updateDraft(e) {
    const value = e.target.value;
    if (value.length) localStorage.setItem("new-post-draft", value);
    else this.clearDraft();
  }
  clearDraft() {
    localStorage.removeItem("new-post-draft");
  }

  async #postAction() {
    const content = this.#contentEditorRef.value.getFormValue();
    const imageFile = this.#contentEditorRef.value.files[0];

    if (!content || content.length <= 1) {
      return showAlert({
        title: msg("Error publishing post", {id: 'error-publishing-post'}),
        message: msg("The post contents can not be empty"),
      });
    }

    const task = async () => {
      this.loading = true;

      let imageBase64;
      if (imageFile) {
        const fileSizeInBytes = imageFile.size;
        const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
        const maxSize = "10MB";
        if (fileSizeInMB > 10) {
          return showAlert({
            title: msg("Selected image is too large"),
            message: msg(str`Please upload an image smaller than ${maxSize}.`),
          });
        }
        try {
          const resizedImage = await resizeImage(imageFile, {
            quality: 0.7,
          });
          imageBase64 = resizedImage.url;
        } catch {
          imageBase64 = await imageToBase64(imageFile);
        }
      }

      const postData = {
        content,
        ...(imageBase64 && { header_image: imageBase64 }),
      };
      if (this.postAs && this.postAs.id) postData['channel_id'] = this.postAs.id;
      const result = await this.#domain.publishPost(postData);

      if (! result) {
        return;
      }

      app.addToastMessage(msg("Post has been published successfully"), {
        type: "success",
      });

      // Reset content editor when successfully posted.
      this.#contentEditorRef.value.reset(1);
      this.postEditorActivated = false;

      this.clearDraft();

      if (!result) return;

      // Add to the current view.
      this._computedEntries.unshift({
        id: "xxxxx-xxxxx-xxxxx-xxxxx",
        post_id: result.data.id,
        post: result.data,
        created_at: result.data.created_at,
      });
      this.requestUpdate();

      setTimeout(async () => {
        await this.#refreshTimeline();
      }, 1000);
    };

    return Task.run(task, {
      description: msg("Publishing post...", {id: 'publishing-post'}),
      ghost: this.#createPostRef.value,
    });
  }

  async #loadTimeline(query = {}) {
    query = { ...query, ...(this.#nextCursor && { cursor: this.#nextCursor }) };
    const task = async () => {
      try {
        this.loading = true;
        const response = await this.#domain.getTimeline({ query });
        this.notReady = !!response.not_ready;
        this.entries = response.data;

        this.pagination = response.pagination;
        this.#nextCursor = response.pagination.next_cursor;
        this.loading = false;
      } catch (err) {
        app.addToastMessage(`Timeline: ${err}`, { type: "error" });
      }
      this.requestUpdate();
      if (this.notReady) {
        setTimeout(async () => {
          await this.#loadTimeline(query);
        }, 5000);
      }
    };

    await Task.run(task);
  }

  async #loadChannels() {
    return Task.run(async () => {
      let myChannels;
      try {
        let myChannels = app.cache.getValue('my-channels');
        if (myChannels !== undefined && myChannels !== null) {
          this.myChannels = myChannels;
          return;
        }
      } catch {
        // Nothing
      }

      if (! myChannels) {
        try {
          const response = await this.#channelDomain.myList({per_page: 100, admin: true});
          if (response && response.data) {
            this.myChannels = response.data;
            app.cache.setValue('my-channels', response.data);
          }
        } catch(e) {
          console.error('Error with retrieving my channels', e);
          // nothing
        }
      }
    });
  }

  #changePostAs(e) {
    const channelId = e.target.dataset['channel'];
    const name = e.target.dataset['name'];
    if (channelId && name) {
      this.postAs = {id: channelId, name};
    } else {
      this.postAs = null;
    }
  }

  async connectedCallback() {
    super.connectedCallback();

    if(!this.widget) {
      this.#loadChannels();
      this.#loadTimeline();
      this.addEventListener("refresh", this.#refreshTimeline);
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    if(!this.widget) {
      this.removeEventListener("refresh", this.#refreshTimeline);
    }
  }

  renderEntry(entry, index) {
    if (!entry) return html`<general-shimmer type="post"></general-shimmer>`
    const userId = entry?.post?.user_id || entry?.repost?.user_id;
    const isMe = userId === app.session.user.id;
    const channelId = entry?.post?.channel_id || entry?.repost?.channel_id;
    const isMeChannelAdmin = this.myChannels.some(e => e.id === channelId);

    return html`<post-entry
      .index=${index}
      .entry=${entry}
      .stats=${entry.stats}
      ?editAllowed=${isMe || isMeChannelAdmin}
      @refresh=${this.#refreshTimeline.bind(this)}
    ></post-entry>`;
  }

  async #onCnvScrollEndPage() {
    if (this.entries?.length && !this.pagination?.next_cursor) return;
    await this.#loadTimeline();
  }

  async #refreshTimeline() {
    this.#nextCursor = null;
    this.#fullList = [];
    await this.#loadTimeline();
  }

  async #onRangeChanged(e) {
    const { last } = e;
    if (last > this.#fullList.length && !this.loading) {
      await this.#onCnvScrollEndPage();
    }
  }

  renderWidget() {
    return html` ${this.renderPostArea(true)} `;
  }

  getWidgetSettings() {
    return {
      ...super.widgetSettings,
      priority: 1000,
      full: true,
      title: msg("Timeline"),
    };
  }

  renderPostArea(isWidget) {
    return html`<section ${ref(this.#createPostRef)} class="card">
      <form action="" method="post" ${ref(this.#editorFormRef)}>
        <post-rich-editor
          id="editor"
          ${ref(this.#contentEditorRef)}
          ?fixed-toolbar=${!isWidget}
          allow-files
          name="content"
          fileName="header_image"
          accept="image/*"
          placeholder="${msg('Type here to create a post...')}"
          value="${this.draft}"
          rows=${isWidget ? 2 : this.postEditorActivated ? 4 : 1}
          @click=${() => (this.postEditorActivated = true)}
          @input=${throttle(this.updateDraft.bind(this), 500)}
        >
        </post-rich-editor>
        ${isWidget ? html`
          <flex-container>
            <flex-item class="col-6">
              <a
                class="button tiny blue outline wide"
                href="/timeline"
              >
                ${msg('Go to Timeline')}
              </a>
            </flex-item>
            <flex-item class="col-6">
              <button
                type="button"
                class="tiny wide blue"
                name="publish"
                @click="${this.#postAction}"
              >
                ${msg('Publish post')}
              </button>
            </flex-item>
          </flex-container>
        ` : html`
          <flex-container>
            ${this.myChannels && this.myChannels.length > 0
              ? html`<flex-item class="col-5">
                <nav data-dropdown class="wide">
                  <button title="Post As" type="button" class="wide small outline">
                    ${msg(str`Post as: ${this.postAs ? this.postAs.name : app.session.user?.firstname}`)}
                  </button>
                  <menu>
                    <li><a @click=${this.#changePostAs.bind(this)} data-user>
                      <svg-icon icon="account"></svg-icon> ${msg(str`Post as: ${app.session.user?.firstname}`)}
                    </a></li>
                    <li><hr /></li>
                    ${repeat(this.myChannels, (c) => html`
                      <li><a @click=${this.#changePostAs.bind(this)} data-channel=${c.id} data-name=${c.name}>
                        <svg-icon icon="channels" size="26px"></svg-icon>
                        ${c.name}
                      </a></li>
                    `)}
                  </menu>
                </nav>
              </flex-item>`
              : nothing}
            <flex-item class="${this.myChannels && this.myChannels.length > 0 ? "col-7" : "col-12"}">
              <button
                type="button"
                class="small wide"
                name="publish"
                @click="${this.#postAction}"
              >
                ${msg('Publish post')}
              </button>
            </flex-item>
          </flex-container>
        `}
      </form>
    </section>`;
  }

  renderPage() {
    return html`
      ${this.renderPostArea()}

      ${this.notReady
        ? html`
            <section class="card">
              <div class="center">
                <img src="/assets/img/balance.svg" />
              </div>
              <section class="card">
                <h3>
                  ${msg('We are loading your experience...')}
                </h3>
                <p>
                  ${msg(`Please give us a moment as we prepare your personalized content. This process may take some time as we're tailoring the experience to perfectly suit your needs. In the meantime, we invite you to take a deep breath, step back from the busyness of life, and savor this moment of calm. Your content will be ready shortly. Thank you for your patience.`, {desc: 'When the timeline has not been loaded yet for a new user this will be displayed at some point and will disappear after a while.'})}
                </p>
              </section>
            </section>
          `
        : nothing}

      ${this.#renderList()}
    `;
  }

  #renderList() {
    if (this._computedEntries.length === 0) {
      const shimmerList = Array.from({ length: 5 }, () => ({}));
      return html`
        ${repeat(
          shimmerList,
          () => html`<general-shimmer type="post"></general-shimmer>`
        )}
      `;
    }

    return html`<lit-virtualizer .items=${this._computedEntries} .renderItem=${this.renderEntry.bind(this)} @rangeChanged=${this.#onRangeChanged}></lit-virtualizer>`
  }

  willUpdate(changeProps) {
    super.willUpdate(changeProps);

    if (changeProps.has('entries') && this.entries.length !==0 ) {
      this.#fullList = [...this.#fullList, ...this.entries];

      const shimmerList = this.#nextCursor ? Array.from({ length: 20 }, () => undefined) : [];
      this._computedEntries = [...this.#fullList, ...shimmerList];
    }
  }
}
