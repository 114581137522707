import { askConfirm } from "@qogni-technologies/design-system/src/components/base/modal-dialog.js";
import { markdownToHtml } from "@qogni-technologies/design-system/src/shared/common";
import { LitElement, html, nothing } from "lit";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { MessagesDomain } from "../../domain/messages-domain";

export class MessageItem extends LitElement {
  createRenderRoot() {
    return this;
  }

  #domain;

  static get properties() {
    return {
      message: { type: Object },
      conversationId: { type: String },
      after: { type: Object },
      sender: { type: Boolean, reflect: true, attribute: true },
      divider: { type: Boolean }
    };
  }

  constructor() {
    super();
    this.#domain = new MessagesDomain();
  }

  connectedCallback() {
    super.connectedCallback();

    this.addEventListener("touchstart", this.#startLongPress);
    this.addEventListener("touchend", this.#stopLongPress);
    this.addEventListener("touchmove", this.#stopLongPress);
  }

  render() {
    if (!this.message) return html`<general-shimmer></general-shimmer>`;
    const isMe = this.message.user_id === app.session.user?.id;
    const isPending = this.message.pending === true;

    // const nextMessage = this._computedMessageList.at(idx + 1);
    // const prevMessage = this._computedMessageList.at(idx - 1);
    // let after = nothing;

    return html`
      <div class="content">
        <section>
          <div>${unsafeHTML(markdownToHtml(this.message.content))}</div>
          ${isPending
            ? "..."
            : html`<time
                datetime="${this.message.created_at}"
                data-update-interval="30000"
              ></time>`}
        </section>
        ${isMe
          ? html`<nav
              id="account"
              data-dropdown
              class="${isMe ? "align-right" : ""} img-button"
            >
              <button title="Menu" class="simple">
                <svg-icon icon="meatballs"></svg-icon>
              </button>
              <menu>
                <li class="delete">
                  <a @click=${() => this.#onDelete(this.message)}
                    ><svg-icon icon="trash" size="20px"></svg-icon>Delete</a
                  >
                </li>
              </menu>
            </nav>`
          : nothing}
      </div>
    `;
  }

  #longPressTimeout = null;

  #startLongPress(e) {
    const messageItem = e.currentTarget;

    if (!messageItem.classList.contains("sender")) return;

    this.#longPressTimeout = setTimeout(() => {
      const button = messageItem.querySelector('button[title="Menu"]');
      button.click();
    }, 500);
  }

  #stopLongPress() {
    clearTimeout(this.#longPressTimeout);
  }

  async #onDelete(message) {
    const userConfirmed = await askConfirm({
      title: "Delete Message?",
      message: "Are you sure you want to delete message for everyone?",
    });

    if (!userConfirmed) return;

    await this.#domain.deleteMessages(this.conversationId, message.id);
    this.dispatchEvent(new CustomEvent("refresh"));
  }

  updated(changeProps) {
    if (changeProps.has("message")) {
      setTimeout(() => {
        app.enhancers.run(this);
      });
    }
  }
}

customElements.define("message-item", MessageItem);
