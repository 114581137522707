import { FlowPage, UI as baseUI } from "../shared/flow-page";
import { LitElement, html, nothing } from "lit";
import { Converter } from "showdown";
import { ApiRequest } from "../shared/APIRequest";
import { msg, str } from "@lit/localize";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { repeat } from "lit/directives/repeat.js";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { createRef, ref } from 'lit/directives/ref.js';
import { FutureSelfDomain } from "../domain/future-self";

const UI = {
  ...baseUI,

  chatBox: {
    ...baseUI.longtext,
    class: "chat-box auto-grow speech-enabled",
    rows: 3,
    placeholder: "Type your message...",
    stepClass: "actor-user",
    on: {
      keydown: (e) => {
        if (e.key === "Enter")
          if (!e.shiftKey) {
            e.preventDefault();
          }
      },
      keyup: (e) => {
        if (e.key === "Enter")
          if (!e.shiftKey) {
            e.target.closest("form").requestSubmit();
          }
      },
    },
  },

  startNewChat: {
    renderInput: (step) => {
      return html`<button @click=${() => step.resolve(1)}>
        ${msg("Start new chat")}
      </button>`
    }
  }
};

export class PageAIBuddy extends FlowPage {
  #api = ApiRequest.factory();
  #futureSelfDomain;

  #sessionMessagesPagination;

  #fullSessionMessages = []

  #navDrawerRef = createRef();

  static get properties() {
    return {
      _selectedSessionId: { type: String },

      _computedSessionMessage: { type: Array },
      _sessionMessages: { type: Array }
    };
  }

  constructor() {
    super();
    this.#futureSelfDomain = new FutureSelfDomain();
    this._computedSessionMessage = [];
  }

  async #getSessionMessages() {
    const task = async () => {
      const nextCursor = this.#sessionMessagesPagination?.next_cursor;

      const options = {...(nextCursor && {cursor: nextCursor}), per_page: 100};

      const response = await this.#futureSelfDomain.getMessages(this._selectedSessionId, options);
      this._sessionMessages = response?.data;
      this.#sessionMessagesPagination = response?.pagination;
    }

    await Task.run(task)
  }

  flowInit(wf) {
    wf.options.strings.continue = "Send";

    // custom action
    wf.install("disclaimer", this.disclaimer.bind(this));
    wf.install("reply", this.reply.bind(this));
    wf.install("question", this.question.bind(this));

    wf.on("step-completed-ui-render", (e) => {
      e.detail.render = (step) => {
        return html` <div data-completed-step>
          <div class="pre-wrap">${step.value || ""}</div>
          ${this.renderTime(new Date(step.completedAt))}
        </div>`;
      };
    });
  }

  render() {
    return html`
      <nav-drawer ${ref(this.#navDrawerRef)} backdrop>
        <button slot="actions" class="small round white" @click=${() => this.#navDrawerRef.value.toggle()}> <svg-icon icon="menu"></svg-icon></button>
        <button slot="actions" class="small round white" @click=${this.#onCreateNewCnv} data-tooltip=${msg("Start new chat")} data-tooltip-position="bottom"> <svg-icon icon="pencil"></svg-icon></button>
        <chatbot-session-list @session-change=${this.#onSelectedSessionChange}></chatbot-session-list>
      </nav-drawer>
      <button slot="actions" class="menu-btn small round white" @click=${() => this.#navDrawerRef.value.toggle()}> <svg-icon icon="menu"></svg-icon></button>
      <div>${super.render()}</div>
    `
  }

  async #onCreateNewCnv(fromDrawer = true) {
    if (fromDrawer) {
      this.#navDrawerRef.value.toggle();
    }
    this._selectedSessionId = undefined;
    return Task.run(async () => {
      await this.#futureSelfDomain.createNewChatBotSession();
    }, { ghost: this })
  }

  #onSelectedSessionChange(e) {
    this._selectedSessionId = e.target.selectedId;
    this.#navDrawerRef.value.toggle();
    this.#fullSessionMessages = [];
  }

  #thread = [];
  async flowStart(wf) {

    this.state = {
      user: app.session.user.firstname,
      language: app.session.user.language,
    };

    wf.disclaimer(
      msg(
        str`Note: Answers from our AI-chatbot might be incorrect. Please use caution when using, when in doubt, always ask your healthcare professional.`
      ),
      {
        local: true,
        stepClass: "disclaimer",
      }
    );

    if (this._selectedSessionId) {
      if (this._computedSessionMessage.length !== 0) {
        for (const m of this._computedSessionMessage) {
          const { message, role, created_at } = m;
          if (role === "user") {
            wf.question(message, {stepClass: "actor-user", date: created_at});
          } else {
            wf.reply(message, { local: true, date: created_at, stepClass: "intro",})
          }
        }

        await wf.show(html`<button class="outline" @click=${() => this.#onCreateNewCnv(false)}>${msg("Start new chat")}</button>`);
      }

      await new Promise(() => {}); // Wait forever
    }

    wf.reply(msg(str`Hi ${this.state.user}, tell me what's on your mind`), {
      local: true,
      stepClass: "intro",
    });

    let topics = sessionStorage.getItem("current-health-topics-v2");
    if (topics) topics = JSON.parse(topics);

    if (!topics) {
      topics = await Task.run(
        async () => {
          return await this.interactApi(
            `
        List 3 possible questions ${this.state.user} could ask at this point
        about their health and possible actions,
        based on what you know about them. Return JSON in the schema: {questions: [string]}
      `,
            {
              format: "json",
              question: false,
              memorize: false,
            }
          );
        },
        {
          ghost: wf.ui.container,
          description: "Listing questions",
        }
      );

      sessionStorage.setItem("current-health-topics-v2", JSON.stringify(topics));
    }

    const list = html` ${repeat(topics?.questions, (item) => html`<li>${item}</li>`)} `;

    await wf.reply(
      html`<ol @click=${this.takeSuggestion} class="tiles">
        ${list}
      </ol>`,
      {
        local: true,
      }
    );

    if (this.#thread.length !== 0) {
      for (const m of this.#thread) {

        if (m['me']) {
          wf.question(m['me'], {stepClass: "actor-user"});
        } else {
          wf.reply(m['ai'], { local: true, stepClass: "intro",})
        }
      }
    }

    while (true) {
      const question = await wf.ask("", UI.chatBox);
      this.#thread.push({
        me: question,
      });

      const answer = await wf.reply(question);

      this.#thread.push({
        ai: answer,
      });
    }
  }

  takeSuggestion(e) {
    const text = e.target.closest("li")?.textContent.trim();

    if (text) {
      const txtArea = document.querySelector(`textarea[name="step"]`);
      if (txtArea) {
        txtArea.value = text;
        txtArea.closest("form").requestSubmit();
      }
    }
  }

  closeFlow(options) {
    options = {
      ...(options ?? {}),
      force: false,
    };
    super.closeFlow(options);
  }

  get flowType() {
    return "chat";
  }

  get icon() {
    return "search";
  }

  async reply(step) {
    const question = step.topic;
    const date = step.options?.date;

    const answer = step.options.local
      ? step.topic
      : await this.interactWithQoach(question);

    const answerHtml = html`
      <div>
        <div class="">${typeof answer === 'string' ? unsafeHTML(new Converter().makeHtml(answer.replace(/\[doc\d+]/g, ""))) : answer}</div>
        ${this.renderTime(date ? new Date(date) : new Date())}
      </div>
    `;

    step.render = () => {
      return html`${answerHtml}`;
    };
    step.resolve(answer);
  }

  async question(step) {
    let question = step.topic;
    const date = step.options?.date;
    question = this.extractQuestion(question);

    const questionHtml = html`
      <div data-completed-step>
        <div class="pre-wrap">${unsafeHTML(new Converter().makeHtml(question))}</div>
        ${this.renderTime(date ? new Date(date) : new Date())}
      </div>
    `

    step.render = () => {
      return html`${questionHtml}`;
    };

    step.resolve(question);
  }

  extractQuestion (text) {
    const regex = /(?<=Question to react to: )(.*?)(?=\n\s*-{34}\n)/s;
    const match = text.match(regex);
    return match ? match[0].trim() : text;
  }

  async disclaimer(step) {
    step.render = () => {
      return html`${step.topic}`;
    };
    step.resolve(step.topic);
  }

  renderTime(date) {
    date = date ?? Date.now();
    return html`
      <time datetime="${date.toISOString()}">${date.toLocaleTimeString()}</time>
    `;
  }

  async interactWithQoach(message) {
    const response = await this.interactApi(message);

    if (response)
      return response.replace(/\[doc\d+]/g, "");

    return msg("I'm not able to respond to your message.");
  }

  async interactApi(message, options) {
    options = {
      format: "string",
      memorize: true,
      temperature: 0.8,
      question: true,
      promptAddition: '',
      ...options,
    };

    if (options.format === "json") {
      options.promptAddition += `Return JSON in the schema given.`;
    }

    const result = await this.#api.postData(`/users/me/future-self/interact`, {
      memorize: options.memorize,
      message: message,
      format: options.format.toLowerCase() ?? 'string',
      schema: options.schema ?? null,
    });

    const response = result.data?.response?.message;

    if (response && options.format === "json" && typeof response === "string")
      return JSON.parse(response.trim());

    return response;
  }

  firstUpdated() {
    super.firstUpdated();
  }

  async willUpdate(changedProps) {
    await super.willUpdate(changedProps);

    if (changedProps.has('_selectedSessionId') && this._selectedSessionId) {
      this.#getSessionMessages()
    }

    if (changedProps.has('_sessionMessages') && this._selectedSessionId !== 0) {
      const reverseMessages = this._sessionMessages.reverse();
      this.#fullSessionMessages = [...reverseMessages, ...this.#fullSessionMessages];
      this._computedSessionMessage = this.#fullSessionMessages;
    }
  }
}

customElements.define('chatbot-session-list', class ChatbotSessionList extends LitElement {
  createRenderRoot() {
    return this;
  }

  #futureSelfDomain;
  #sessionPagination;

  static get properties() {
    return {
      _sessions: { type: Array },
      selectedId: { type: String }
    };
  }

  connectedCallback() {
    super.connectedCallback();
    this.#fetchSessions();
  }

  constructor() {
    super();
    this.#futureSelfDomain = new FutureSelfDomain();
  }

  async #fetchSessions() {
    const task = async () => {
      const currentPage = this.#sessionPagination?.current_page;
      const lastPage = this.#sessionPagination?.last_page;

      const options = {}


      if (currentPage && currentPage !== lastPage) {
        options.page = currentPage + 1;
      }
      const response = await this.#futureSelfDomain.getSessions(options);
      this._sessions = response?.data ?? [];
      this.#sessionPagination = response?.pagination;
    }

    await Task.run(task, { global: false });
  }

  render() {
    if (Array.isArray(this._sessions) && this._sessions.length === 0 && !this.#sessionPagination) return nothing;

    if (!this._sessions) {
      return html`
        ${repeat(Array.from({ length: 5 }, () => undefined), () => html`<session-item><app-shimmer></app-shimmer></session-item>`)}
      `
    }

    const groupedSessions = Object.entries(this.groupItemsByTimeRange(this._sessions));

    return html`
      ${repeat(groupedSessions, ([groupLabel, sessions]) => {
        if (sessions.length === 0) return nothing;
        return html`
          <hr data-content="${groupLabel}">
          ${repeat(sessions, (s) => {
            return html`
              <session-item class="${this._selectedSessionId === s.id ? "active" : ""}" @click=${() => this.#onSessionClick(s)}>
                <span>${s.subject ? s.subject : msg("Untitled")} </span>
              </session-item>
            `
          })}
        `
      })}
    `;
  }

  groupItemsByTimeRange(items = []) {
    const filteredItems = items.filter(i => i.chatbot_messages_count !== 0);
    const now = new Date(); // Fixed current date for example

    const today = new Date(now);
    today.setHours(0, 0, 0, 0);

    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);

    const sevenDaysAgo = new Date(now);
    sevenDaysAgo.setDate(now.getDate() - 7);

    const tenDaysAgo = new Date(now);
    tenDaysAgo.setDate(now.getDate() - 10);

    const thirtyDaysAgo = new Date(now);
    thirtyDaysAgo.setDate(now.getDate() - 30);

    const timeKeys = [msg("Today"), msg("Yesterday"), msg(str`Previous ${7} Days`), msg(str`Previous ${30} Days`), msg(str`Before ${30} Days`)]

    const groups = {};
    timeKeys.forEach((key) => {
      groups[key] = [];
    })

    filteredItems.forEach(item => {
        const startedAt = new Date(item.started_at);

        if (startedAt.toDateString() === today.toDateString()) {
          groups[timeKeys[0]].push(item);
        } else if (startedAt.toDateString() === yesterday.toDateString()) {
          groups[timeKeys[1]].push(item);
        } else if (startedAt >= sevenDaysAgo && startedAt < yesterday) {
          groups[timeKeys[2]].push(item);
        } else if (startedAt >= tenDaysAgo && startedAt < sevenDaysAgo) {
          groups[timeKeys[3]].push(item);
        } else {
          groups[timeKeys[4]].push(item);
        }
    });

    return groups;
}

  #onSessionClick(s) {
    this.selectedId = s.id;
    this.dispatchEvent(new CustomEvent('session-change'));
  }
})
