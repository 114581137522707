import { ApiRequest } from "../shared/APIRequest";
import { AppDomainHandler } from "../shared/app-domain-handler";
import { removeFalsyValues } from "@qogni-technologies/design-system/src/shared/common";

export class ChannelDomain extends AppDomainHandler {
  #api;

  constructor() {
    super();
    this.#api = ApiRequest.factory();
  }

  async list(options = {}) {
    const query = removeFalsyValues(options);
    try {
      return await this.#api.getData(`/channels?${new URLSearchParams(query)}`);
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async myList(options) {
    const query = removeFalsyValues(options);
    try {
      return await this.#api.getData(`/users/me/channels?${new URLSearchParams(query)}`);
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async detail(channelId) {
    try {
      return await this.#api.getData(`/channels/${channelId}`);
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async follow(channelId) {
    try {
      return await this.#api.postData(`/channels/${channelId}/follow`);
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async unfollow(channelId) {
    try {
      return await this.#api.postData(`/channels/${channelId}/unfollow`);
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async getPosts(channelId, options) {
    let result;
    options = removeFalsyValues(options);

    try {
      const query = {
        page: 1,
        ...(options && {...options}),
      }

      result = await this.#api.getData(`/channels/${channelId}/posts?${new URLSearchParams(query)}`)
    } catch (err) {
      console.error(err);
      return false;
    }

    if (!result) return false;
    return result;
  }

  async addNewUser(channelId, userId, role) {
    let result;

    try {
      result = await this.#api.postData(`/channels/${channelId}/users`, {
        user_id: userId,
        role
      })
    } catch (err) {
      console.error(err);
      return false;
    }

    if (!result) return false;
    return result;
  }

  async removeUser(channelId, userId) {
    let result;

    try {
      result = await this.#api.deleteData(`/channels/${channelId}/users/${userId}`);
    } catch (err) {
      console.error(err);
      return false;
    }

    if (!result) return false;
    return result;
  }

  async changeRole(channelId, userId, role) {
    let result;

    try {
      result = await this.#api.putData(`/channels/${channelId}/users/${userId}`, { role });
    } catch (err) {
      console.error(err);
      return false;
    }

    if (!result) return false;
    return result;
  }

  async create(options) {
    let result;

    options = removeFalsyValues(options);

    try {
      result = await this.#api.postData("/channels", options);
      app.addToastMessage(`${options.name} channel successfully created.`);
    } catch (err) {
      switch (err.response?.status) {
        case 400:
          app.addToastMessage("Validation errors.", {
            type: "error",
          });
          return false;
        case 403:
          app.addToastMessage("You are not allowed to create a channel within the specified organisation.", { type: "error" });
          return false;
        default:
          break;
      }
    }

    if (!result.status) return false;
    return result.data;
  }

  async edit(channelId, options) {
    let result;

    options = removeFalsyValues(options);

    try {
      result = await this.#api.putData(`/channels/${channelId}`, options);
      app.addToastMessage(`${options.name} channel successfully updated.`);
    } catch (err) {
      switch (err.response?.status) {
        case 400:
          app.addToastMessage("Validation errors.", {
            type: "error",
          });
          return false;
        case 403:
          app.addToastMessage("Forbidden. User does not have permission to edit this channel.", { type: "error" });
          return false;
        default:
          break;
      }
    }

    if (!result.status) return false;
    return result.data;
  }
}
