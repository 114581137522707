import { html } from "lit";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { calculateAge } from "../shared/common";
import { msg, str } from "@lit/localize";
import { AccountDomain } from "../domain/account-domain";
import { FlowPage, UI } from "../shared/flow-page";
import { Converter } from "showdown";
import { MasterDataDoamin } from "../domain/master-data-domain";

/**
 * @typedef {Object} Person
 * @property {string} birthDate
 * @property {string} foodPreference
 * @property {boolean} hasAllergies
 * @property {number} healthScore
 * @property {string[]} healthTopics
 * @property {string} height
 * @property {string} job
 * @property {string} language
 * @property {string} lastName
 * @property {string} name
 * @property {number} sexe
 * @property {string} weight
 * @property {number?} age
 * @property {string?} topicSummary
 */

export class OnboardingFlowPage extends FlowPage {
  #urlAfterFlow;

  connectedCallback() {
    super.connectedCallback();

    const params = new URLSearchParams(location.search);
    this.debugHealthFlow = params.has("adv");
    this.healthFlowOnly =
      this.debugHealthFlow || location.pathname === "/onboarding/health";
    this.#urlAfterFlow =
      this.debugHealthFlow || !this.healthFlowOnly ? "/" : "/account";
  }

  get urlAfterFlow() {
    return this.#urlAfterFlow;
  }

  stepRendered() {
    this.checkTranslationStep();
  }

  get options() {
    return {
      ...super.options,
      requireSession: true
    };
  }

  // make sure click on a language button switches the language immediately.
  checkTranslationStep() {
    const lang = this.querySelector("#languages");
    if (lang) {
      const translate = async (code) => {
        app.setLocale(code).then((hasChanged) => {
          if (hasChanged) this.requestUpdate();
        });
        if (code !== app.session.user?.language)
          await AccountDomain.singleton.setSingleProperty("language", code);
      };
      lang.addEventListener("change", (e) => translate(e.target.value));
    }
  }

  //#region flow

  // Flow Entrypoint
  async flowStart(wf) {
    const person = {
      name: app.session?.user?.profile?.firstname ?? "friend",
      language: app.session.user.language,
    };

    if (this.healthFlowOnly) {
      this.#prefillFromUser(person);
    } else {
      person.language = await wf.ask(
        msg("What language should we communicate in?"),
        {
          ...UI.language,
          footnotes: {
            "¹": msg(
              str`App translations into other language than English may currently be incomplete.`
            ),
          },
        }
      );

      await this._1_showIntroduction(wf);

      await this._2_getPersonalDetails(wf, person);
    }

    await this._3_getHealthDetails(wf, person);

    if (!this.healthFlowOnly) {
      await this._4_showUserAround(wf, person);

      await this._5_nextStep(wf, person);
    }
  }

  #prefillFromUser(person) {
    person.age = "unknown";
    person.birthDate = app.session.user.date_of_birth;

    if (person.birthDate) {
      const date = new Date(Date.parse(person.birthDate));
      person.birthYear = date.getFullYear();
      person.age = calculateAge(date);
    }
    person.height = Math.round(app.session.user.body_length ?? 0);
    person.weight = Math.round(app.session.user.body_weight ?? 0);
    person.sexe = app.session.user.sexe;
    person.job = app.session.user.job;
  }

  async _1_showIntroduction(wf) {
    await wf.ui(null, {
      renderInput: () => html` <section class="callout">
        <h3>${msg("We care deeply about your privacy")}</h3>
        <div>
          <p>
            ${msg(
              `Your data is safe with us—we never sell it, and we use it only to personalize your experience on Qogni.`
            )}
          </p>
          <p>
            ${msg("More info")}:
            <a target="_blank" href="https://qogni.com/legal/"
              >${msg(`Privacy Policy`)}</a
            >
            &amp;
            <a target="_blank" href="https://qogni.com/legal/"
              >${msg(`Terms of Service`)}</a
            >.
          </p>

          <p>${msg(`By continuing, you agree to these terms.`)}</p>
        </div>
      </section>`,
    });
  }

  async _2_getPersonalDetails(wf, person) {
    person.name = await wf.ask(msg("What's your first name?"), {
      value: person.name,
      ...UI.firstName,
    });

    person.lastName = await wf.ask(
      msg("And your family name?", {}),
      UI.lastName
    );

    person.birthDate = await wf.ask(
      msg(str`What's your birthdate, ${person.name}?`),
      UI.birthdate
    );
    //
    // const date = new Date(Date.parse(person.birthDate));
    //
    // person.birthYear = date.getFullYear();
    // person.birthMonth = new Date().toLocaleString("default", { month: "long" });
    //
    // person.age = calculateAge(date);
    //
    // const fact =
    //   new Date().getSeconds() & 2
    //     ? `A health-related breakthrough of the year ${person.birthYear}`
    //     : `A date-related health or sports event on day ${date.getDate()} of month ${
    //         person.birthMonth
    //       } (but only on that exact day!)`;
    //
    // const birthFactPrompt = `Give me a random, extremely short, pointy, and factual one-liner in the language '${person.language}'
    //   about ${fact}.
    //
    //   If you're not able to find anything specific, or have ANY problem,
    //   give a (non-American, international) sports highlight on the same day or in the year ${person.birthYear}
    //   in under 10 words instead.`;
    //
    // const quote = await wf.askAI(birthFactPrompt, { scope: false });
    //
    // await wf.ui(null, {
    //   renderInput: () =>
    //     html`<h3>${msg(`Interesting Fact`)}</h3>
    //       <blockquote>${quote}</blockquote>`,
    // });

    person.height = await wf.ask(msg("How tall are you (in CM)?"), UI.height);

    if (person.height < 100) wf.back(UI.height.name);

    person.weight = await wf.ask(
      msg("And what do you weigh (in KG)?"),
      UI.weight
    );

    person.sexe = await wf.ask(`${person.name}, ${msg(`what's your sex?`)} ¹`, {
      ...UI.sexe,
      footnotes: {
        "¹": msg(
          "Your gender (how you identify) is less important when it comes to health issues."
        ),
      },
    });

    person.job = await wf.ask(
      msg(str`Tell me ${person.name}, what do you do for a living?`),
      UI.job
    );

    await wf.reward(html`<h3>${msg(`Awesome!`)}</h3>`);
  }

  async getAIBirthQuote(person, wf) {
    const date = new Date(Date.parse(person.birthDate));

    person.birthYear = date.getFullYear();
    person.birthMonth = new Date().toLocaleString("default", { month: "long" });

    person.age = calculateAge(date);

    const fact =
      new Date().getSeconds() & 2
        ? `A health-related breakthrough of the year ${person.birthYear}`
        : `A date-related health or sports event on day ${date.getDate()} of month ${
            person.birthMonth
          } (but only on that exact day!)`;

    const birthFactPrompt = `Give me a random, extremely short, pointy, and factual one-liner in the language '${person.language}'
      about ${fact}.

      If you're not able to find anything specific, or have ANY problem,
      give a (non-American, international) sports highlight on the same day or in the year ${person.birthYear}
      in under 10 words instead.`;

    const quote = await wf.askAI(birthFactPrompt, { scope: false });
    return quote;
  }

  /**
   *
   * @param {any} wf
   * @param {Person} person
   * @returns {Promise<void>}
   */
  async _3_getHealthDetails(wf, person) {
    person.foodPreference = await wf.ask(
      msg("What kind of diet are you on?"),
      UI.foodChoices
    );

    person.hasAllergies = await wf.ask(
      msg(str`Do you have any allergies or intolerances, ${person.name}?`),
      UI.hasAllergies
    );

    if (person.hasAllergies) {
      person.allergies = await wf.ask(
        msg(`So what are your allergies and/or intolerances?`),
        UI.allergies
      );

      const masterAllergens = MasterDataDoamin.singleton.allergens;
      const hasGluten = !!(person.allergies ?? []).find(
        (a) => masterAllergens.find((i) => i.name.includes("Gluten"))?.id === a
      );

      if (hasGluten)
        person.hasCeliacDisease = await wf.ask(
          msg(`Do you have Celiac Disease?`),
          UI.celiac
        );
    }

    person.healthScore = await wf.ask(
      msg(
        str`How would you rate your current health, overall, ${person.name}?`
      ),
      UI.healthRating
    );

    person.healthTopics = await wf.ask(
      `${msg(str`${UI.healthTopics.scores[person.healthScore]}`)}
        ${msg(`What are the 3 most important health-related topics for you?`)}`,
      UI.healthTopics
    );

    person.topicSummary = await wf.askAI(
      this.createTopicSummarizationPrompt(person)
    );

    if (await wf.ask(person.topicSummary, UI.needPersonalDetails)) {
      person.healthIntention = await wf.ask(
        msg("Add more personal details"),
        UI.intention
      );
    } else {
      AccountDomain.singleton.setSingleProperty(
        "profile.health_intention",
        person.topicSummary
      );
    }

    const advicePrompt = this.createQuickAdvicePrompt(wf, person);

    person.quickAdvice = await wf.askAI(advicePrompt, {
      format: "string",
    });

    app.session.user.profile.health_advice = person.quickAdvice;
    AccountDomain.singleton.setSingleProperty(
      "health_advice",
      person.quickAdvice
    );

    const debugPrompt = this.debugHealthFlow
      ? `<br/>
        <h4>Prompt used</h4>
        <textarea readonly cols="80" rows="10"  style="color: #ee4455;">${advicePrompt}</textarea>`
      : ``;

    return await wf.ui(null, {
      renderInput: () =>
        unsafeHTML(
          `<article><h3>${msg(`Our advice`)}</h3>${new Converter().makeHtml(
            person.quickAdvice
          )}${debugPrompt}</article>`
        ),
    });
  }

  async _4_showUserAround(wf, person) {
    await wf.ui(msg(str`Here's what you can expect in Qogni, ${person.name}`), {
      ...UI.video,
      url: "/assets/videos/onboarding/onboarding-mobile-v1.mp4",
    });
  }

  async _5_nextStep(wf, person) {
    const nextStep = await wf.ask(
      msg(str`Select what you want to do next, ${person.name}...`),
      UI.afterOnboarding
    );

    switch (nextStep) {
      case "bc":
        app.goTo("/braincheck", {
          force: true,
        });
        break;
      default:
        app.goTo("/", {
          force: true,
        });
        break;
    }
  }

  //#endregion

  //#region Local Flow actions

  //#endregion

  //#region helpers
  /**
   *
   * @param {Person} person
   * @returns {string}
   */
  createTopicSummarizationPrompt(person) {
    return `${
      person.name
    } indicates that the most important health-related topics for them to work on are:

    ${person.healthTopics.join(", ")}

    Summarize and paraphrase the above in the language '${
      person.language
    }', and make this an INTENTION the the person sets for themselves (speaking in the first person), in normal, concise sentences, not using bullet points.`;
  }

  /**
   *
   * @param {any} wf
   * @param {Person} person
   * @returns {string}
   */
  createQuickAdvicePrompt(wf, person) {
    const date = new Date(Date.parse(person.birthDate));
    person.birthYear = date.getFullYear();
    person.age = calculateAge(date);

    let personSpec = "person",
      nouns = "themselves";
    switch (person.sexe) {
      case 1:
        personSpec = "man";
        nouns = "himself";
        break;
      case 2:
        personSpec = "woman";
        nouns = "herself";
        break;
    }
    let allergySummary = "";
    if (person.hasAllergies && person.allergies?.length)
      allergySummary = `, and suffers from these allergies/intolerances: ${person.allergies.join(
        ", "
      )}`;

    let hti = 0;
    const healthTopicList = person.healthTopics
      .map((name) => {
        hti++;
        return `${hti}: ${name}
`;
      })
      .join("");

    const foodPreferences = MasterDataDoamin.singleton.foodPreferences;
    const singleFp = foodPreferences.find(
      (e) => e.id === person.foodPreference
    );

    const prompt = `Give quick advice in the language '${person.language}' to ${person.name}, a ${personSpec} aged ${person.age}, who rates ${nouns} as ${person.healthScore}/5 in terms of overall health.
When giving advice, please address ${person.name} directly and in an informal manner.
Before the actual advice, please start by summarizing/paraphrasing the health intentions provided by ${person.name} as quoted below:
"${person.topicSummary}"
These are ${person.name}'s most important health topics, ordered by priority:
${healthTopicList}
By the way, ${person.name} has a ${singleFp.name} diet ${allergySummary}.
Please make your advice extremely concise, separated into 3 groups (presented as H3 headings):
food/nutrition, behavior, and 'other'.`;

    return prompt;
  }

  //#endregion
}
