import { AuthenticatedMixin, PWAPage } from "../shared/pwa-page";

import { UI, QogniFlow, selectOne } from "./qogni-flow";
import { parseHTML } from "@qogni-technologies/design-system/src/shared/common";
import { SpeechTranscriber } from "../shared/speech-transcriber";
import { msg, str } from "@lit/localize";
import { QOGNI } from "./qogni";
import { html } from "lit";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
export { UI, selectOne };

/**
 * Base flow page
 */
export class FlowPage extends AuthenticatedMixin(PWAPage) {
  connectedCallback() {
    super.connectedCallback();
    this.flow = new QogniFlow(this.options);

    document.querySelector("global-menu").style="display:none"
  }

  disconnectedCallback(){
    document.querySelector("global-menu").removeAttribute("style")
  }

  get options() {
    return {
      root: this.renderRoot,
      start: this.flowStart.bind(this),
      flowType: this.flowType ?? "full-page",
      on: {
        init: (wf) => {
          this.flowInit(wf);

          wf.options.strings.back = "";

          wf.on("flow-ended", () => {
            setTimeout(() => {
              app.goTo(this.urlAfterFlow, {
                force: true,
              });
            }, 500);
          })
            .on("step-started", this.stepStarted.bind(this))
            .on("step-rendered", this.stepRendered.bind(this));
        },
      },
      requireSession: false
    };
  }

  get flowType() {
    return "full-page";
  }

  // eslint-disable-next-line no-unused-vars
  flowInit(wf) {
    // subclass
  }

  render() {
    return html`
      ${this.renderWatermark()} ${this.flow.render()}
      <button
        @click=${this.closeFlow}
        class="tiny round outline red close-flow-ui"
      >
        <svg-icon icon="close"></svg-icon>
      </button>
    `;
  }

  closeFlow(options) {
    this.flow.workflow.end();

    if (window.history.length > 1) history.back();
    else
      app.goTo("/", {
        force: options.force ?? true,
      });
  }

  async showHtml(wf, htmlToRender, title) {
    await wf.ui(null, {
      renderInput: () => {
        return unsafeHTML(
          `<div class="limited suppress-glossary">${htmlToRender}</div>`
        );
      },
      title: title,
    });
  }

  stepRendered() {
    this.tryEnableSpeech();
  }

  stepStarted() {
    // to be implemented by host
  }

  get urlAfterFlow() {
    return "/";
  }

  //#region flow

  // Flow Entrypoint
  // eslint-disable-next-line no-unused-vars
  async flowStart(wf) {
    throw new Error("Not implemented");
  }

  //#endregion

  //#region Local Flow actions

  //#endregion

  //#region helpers

  tryEnableSpeech() {
    this.querySelectorAll(".speech-enabled").forEach((txt) => {
      txt.style = "padding-right: 55px";
      txt.parentElement.style = "position: relative";
      const btn = parseHTML(
        /*html*/ `<button type="button" title="Press and hold to record..." class="small outline round enable-speech" ><svg-icon icon="microphone"></svg-icon></button>`
      )[0];
      let started;
      txt.parentElement.appendChild(btn);

      const onStart = (e) => {
        e.preventDefault();
        started = new Date();
        this.enableSpeech(txt);
      };
      const onStop = () => {
        const elapsed = new Date() - started;
        if (this.transcriber) this.transcriber.stop();
      };

      btn.addEventListener("mousedown", onStart);
      btn.addEventListener("touchstart", onStart);

      btn.addEventListener("mouseup", onStop);
      btn.addEventListener("touchend", onStop);
    });
  }

  enableSpeech(input) {
    const me = this;

    if (me.transcriber && me.transcriber.recording) {
      me.transcriber.stop();
      return;
    }

    input._placeholder = input.getAttribute("placeholder");

    if (me.transcriber) me.transcriber.stop();

    me.transcriber = new SpeechTranscriber(app.session.user.language, function (
      data
    ) {
      input.value = (input.value + ("\n" + data)).trim();
      input.blur();
    })
      .on("start", () => {
        const lng =
          QOGNI.meta.languages[app.session.user.language] ?? "English";
        input.setAttribute(
          "placeholder",
          msg(str`Listening (${lng}), please speak...`)
        );
        me.classList.toggle("listening", true);
      })
      .on("stop", () => {
        input.setAttribute("placeholder", input._placeholder);
        me.classList.toggle("listening", false);
        me.transcriber = null;
        input.focus();
      })
      .start();
  }

  //#endregion
}
