import { LitElement, html, nothing } from "lit";

customElements.define(
  "recipe-range-rating-view",
  class RecipeRangeRatingView extends LitElement {
    createRenderRoot() {
      return this;
    }

    static get properties() {
      return {
        rating: { type: Object },
        link: { type: String },
        count: { type: Number },
      };
    }

    render() {
      if (this.link && !this.rating) {
        return html`<a href=${this.link}>
          <button class="tiny white">
            <svg-icon icon="plus" size="16px"></svg-icon>Add rating
          </button>
        </a>`;
      }
      if (!this.rating) return nothing;

      const { bayesian_average } = this.rating;

      const content = html`
        <label class="avg-rating">${bayesian_average.toFixed(1)}</label>
        <range-rating-view
          class="beige-bg"
          size="16px"
          .value=${bayesian_average}
        ></range-rating-view>
        ${this.count
          ? html`<label class="total-reviews">(${this.count})</label>`
          : nothing}
      `;

      if (this.link) {
        return html` <button class="tiny white">
          <a href=${this.link}> ${content} </a>
        </button>`;
      }
      return content;
    }
  }
);
