import { html } from "lit";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  PWAPage,
} from "../../../shared/pwa-page";
import { ChannelDomain } from "../../../domain/channel-domain";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { msg } from '@lit/localize';
import { GenericDomain } from "../../../domain/generic-domain";
import { createRef, ref } from 'lit/directives/ref.js';

export class PageNewChannel extends OnboardedMixin(
  AuthenticatedMixin(PWAPage)
) {
  #domain;
  #tagsDomain;
  #categoryDomain;

  #tagsRef = createRef();
  #categoryRef = createRef();

  static get properties() {
    return {
      id: { type: String, routeOrigin: "pathname" },
      channel: { type: Object },
      createNew: { type: Boolean }
    };
  }

  constructor() {
    super();
    this.#domain = new ChannelDomain();
    this.#tagsDomain = new GenericDomain('/tags');
    this.#categoryDomain = new GenericDomain('/question_categories');
  }

  async connectedCallback() {
    await super.connectedCallback();
    this.createNew = this.id === "new";

    if (!this.createNew) {
      await this.#getDetails();
    }
  }

  async #getDetails() {
    return Task.run(
      async () => {
        const res = await this.#domain.detail(this.id);
        this.channel = res.data;
      },
      { ghost: this }
    );
  }

  async #createNewChannel(e) {
    if (e.detail?.name !== "--submit") return;

    let response;
    

    const task = async () => {
      const name = e.detail.value["channel-name"];
      const description = e.detail.value["channel-description"];
      const selectedTags = this.#tagsRef.value?.value ?? [];
      const tags = selectedTags.length === 0 ? undefined : selectedTags.map(e => e.text);
      const selectedCategories = this.#categoryRef.value?.value ?? [];
      const categories = selectedCategories.length === 0 ? undefined : selectedCategories.map(e => e.id);

      const options = {name, ...(description && {description}), ...(tags && {tags}), ...(categories && {question_categories: categories})}
      
      if (this.createNew) {
        response = await this.#domain.create(options);
      } else {
        response = await this.#domain.edit(this.id, options);
      }
    }

    await Task.run(task, {
      ghost: this,
    });

    if (!response) return false;
    window.location.replace(`/network/channels/${response.slug ?? response.id}/view`);
  }

  tagsAutocomplete() {
    return {
      categories: {
        Tags: {
          trigger: () => {
            return true;
          },

          getItems: async (options) => {
            const request = await this.#tagsDomain.list({
              filter: options.search,
              per_page: 10,
              abortPrevious: true,
            });
            return request.data.map((i) => {
              return {
                text: i.name,
                ...i
              };
            });
          },
        },
      }
    }
  }

  categoryAutocomplete() {
    return {
      categories: {
        Category: {
          trigger: () => {
            return true;
          },

          getItems: async (options) => {
            const request = await this.#categoryDomain.list({
              filter: options.search,
              per_page: 10,
              abortPrevious: true,
            });
            
            return request.data.map((i) => {
              return {
                text: i.name,
                ...i
              };
            });
          },
        },
      }
    }
  }

  render() {
    return html`
      <div class="center">
        <h1>${this.createNew ? msg('New') : msg('Edit')} ${msg("Channel")}</h1>
      </div>
      <section class="card">
        <x-form @action=${this.#createNewChannel}>
          <form>
            <input
              name="channel-name"
              data-label=${msg("Name")}
              required
              placeholder=${msg("Channel Name")}
              value=${this.channel?.name}
            />
            <label>
              ${msg("Description")}
              <textarea
                name="channel-description"
                rows="4"
                placeholder=${msg("Write a description here...")}
                .value="${this.channel?.description ?? ''}"
              ></textarea>
            </label>
            <label>
              <span data-label="">Tags</span>
              <tag-list ${ref(this.#tagsRef)} .autocomplete=${this.tagsAutocomplete()} textExpression="name" valueExpression="id" .value=${this.channel?.tags}> </tag-list>
            </label>
            <label>
              <span data-label="">Category</span>
              <tag-list ${ref(this.#categoryRef)} .autocomplete=${this.categoryAutocomplete()} textExpression="name" valueExpression="id" .value=${this.channel?.question_categories}> </tag-list>
            </label>
            <button type="submit" class="small wide" name="publish">${msg("Save")} ${msg("Channel")}</button>
          </form>
        </x-form>
      </section>
    `;
  }
}
