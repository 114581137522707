import { html, nothing } from "lit";
import { PWAPage } from "../shared/pwa-page";

export class PageOffline extends PWAPage {
  #onOnlineBound;

  static get properties() {
    return {
      _showTryAgain: { type: Boolean },
      _loading: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.#onOnlineBound = this.#onOnline.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener("online", this.#onOnlineBound);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener("online", this.#onOnlineBound);
  }

  render() {
    return html`
      <svg-icon icon="offline" size="36px"></svg-icon>
      <h3>You're offline</h3>
      <h5>Please check your internet connection</h5>
      ${!this._showTryAgain
        ? nothing
        : html`<button @click=${this.#onTryAgain}>
            ${!this._loading
              ? nothing
              : html`<svg-icon icon="spinner" size="24px" spinner></svg-icon>`}
            Try again
          </button>`}
    `;
  }

  #onOnline() {
    setTimeout(() => {
      this._showTryAgain = true;
    }, 5000);
  }

  async #onTryAgain() {
    this._loading = true;
    const status = await app.session.apiStatus();
    if (!status) return;

    location.replace(`/`);
  }
}
