import { showAlert } from "@qogni-technologies/design-system/src/components/base/modal-dialog";
import { askConfirm } from "@qogni-technologies/design-system/src/components/base/modal-dialog.js";
import {
  imageToBase64,
  throttle,
} from "@qogni-technologies/design-system/src/shared/common";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { html, nothing } from "lit";
import { createRef, ref } from "lit/directives/ref.js";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { Converter } from "showdown";
import { AccountDomain } from "../../../domain/account-domain";
import { ChannelDomain } from "../../../domain/channel-domain";
import { TimelineDomain } from "../../../domain/timeline-domain";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  PWAPage,
} from "../../../shared/pwa-page";
import "../../../shared/web-components/manage-users";
import { resizeImage } from "@qogni-technologies/pwa-utils-library/src/utils/resize-image";
import { msg, str } from '@lit/localize';

export class PageSingleChannel extends OnboardedMixin(
  AuthenticatedMixin(PWAPage)
) {
  #domain;
  #accountDomain;
  #timelineDomain;

  #postsCurrentPage = 0;
  #postsPerPage = 5;
  #postsLastPage;
  #infinityListRef = createRef();
  #addUserFormRef = createRef();
  #roleSelectRef = createRef();
  #followButtonRef = createRef();
  #editorFormRef = createRef();
  #contentEditorRef = createRef();

  static get properties() {
    return {
      id: { type: String, routeOrigin: "pathname" },
      channel: { type: Object },
      posts: { type: Array },
      addUser: { type: Boolean },

      connectionsList: { type: Array },
      computedConnectionsList: { type: Array },

      _selectedUser: { type: Object },
      _allowedEdit: { type: Boolean },
      postEditorActivated: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.#domain = new ChannelDomain();
    this.#accountDomain = new AccountDomain();
    this.#timelineDomain = new TimelineDomain();

    this.addUser = false;
  }

  async connectedCallback() {
    await super.connectedCallback();

    if (this.id) {
      this.#getDetails();
      this.#getConnections();
    }

    const roles = app.session.user.roles ?? [];
    this._allowedEdit = roles.some(e => e.badge);
  }

  async #getDetails() {
    const task = async () => {
      const res = await this.#domain.detail(this.id);
      if (res) {
        this.channel = res.data;
      }
    };

    Task.run(task, {
      ghost: this,
      description: "Loading channel details...",
    });
  }

  async #getPosts() {
    const task = async () => {
      const options = {
        per_page: this.#postsPerPage,
        page: this.#postsCurrentPage,
      };

      const response = await this.#domain.getPosts(this.id, options);
      this.posts = response?.data;
      this.#postsLastPage = response?.pagination?.last_page;
    };

    await Task.run(task);
  }

  async #onScrollEnd() {
    this.#postsCurrentPage++;
    if (
      this.#postsCurrentPage === 1 ||
      this.#postsCurrentPage <= this.#postsLastPage
    ) {
      await this.#getPosts();
      this.#infinityListRef.value.addItems(this.posts);
    }
  }

  async #refreshPosts() {
    this.#postsCurrentPage = 1;
    await this.#getPosts();
    this.#infinityListRef.value.addItems(this.posts, true);
  }

  async #onChangeRole(item, role) {
    const task = async () => {
      const { id, firstname, lastname } = item;
      const newRole = role === "owner" ? "editor" : "owner";

      const userConfirmed = await askConfirm({
        title: msg("Change role?", {desc: "To notify user to change role of other user."}),
        message: msg(str`Are you sure you want to change role of ${firstname} ${lastname} to ${newRole}?`, { desc: "Confirmation prompt to change the user's role." }),
        okText: msg("Confirm"),
      });

      if (!userConfirmed) return;

      await this.#domain.changeRole(this.id, id, newRole);
      await this.#getDetails();
    };

    await Task.run(task, {
      ghost: this,
      description: "changing role...",
    });
  }

  async #onRemoveUser(item) {
    const task = async () => {
      const { id, firstname, lastname } = item;
      const userConfirmed = await askConfirm({
        title: msg("Remove User?", { desc: "Confirmation heading when user want to remove specific user."}),
        message: msg(str`Are you sure you want to remove ${firstname} ${lastname} from channel?`, { desc: "Confirmation message when user want to remove specific user from the channel."}),
        okText: msg("Confirm"),
      });

      if (!userConfirmed) return;

      await this.#domain.removeUser(this.id, id);
      await this.#getDetails();
    };

    await Task.run(task, {
      ghost: this,
      description: "Removing user from channel...",
    });
  }

  async #getConnections() {
    const task = async () => {
      const response = await this.#accountDomain.getConnections({
        per_page: 250,
      });
      this.connectionsList = response.data;
    };

    await Task.run(task, {
      ghost: this,
      description: "Loading users.",
    });
  }

  #onUserSelect(e) {
    this._selectedUser = e.detail;
  }

  async #addSelectedUser() {
    const valid = this.#addUserFormRef.value.reportValidity();
    if (!valid || !this._selectedUser) return;

    const task = async () => {
      const userId = this._selectedUser.id;
      const role = this.#roleSelectRef.value.value;
      await this.#domain.addNewUser(this.id, userId, role);
      this.addUser = false;
      await this.#getDetails();
    };

    await Task.run(task, {
      ghost: this,
      description: "Adding new users...",
    });
  }

  async #channelFollowToggle() {
    const task = async () => {
      const { id, is_following } = this.channel;
      if (is_following) {
        const userConfirmed = await askConfirm({
          title: msg("Please confirm?"),
          message: msg(`Are you sure you want to unfollow the channel?`, {desc: "Message to ask confirmation before unfollow channel"}),
          okText: msg("Confirm"),
        });

        if (!userConfirmed) return;
        await this.#domain.unfollow(id);
      } else {
        await this.#domain.follow(id);
      }

      await this.#getDetails();
    };

    await Task.run(task, {
      ghost: this.#followButtonRef.value,
    });
  }

  #onUsersAction(e) {
    const { action, user } = e.detail;
    const { role } = user;

    switch (action) {
      case "change-role":
        this.#onChangeRole(user, role);
        break;
      case "remove":
        this.#onRemoveUser(user);
        break;
      default:
        break;
    }
  }

  willUpdate(changeProps) {
    super.willUpdate(changeProps);

    if (
      this.channel &&
      this.connectionsList &&
      (changeProps.has("channel") || changeProps.has("connectionsList"))
    ) {
      const { users } = this.channel;
      const usersId = users.filter((e) => e.user).map((e) => e?.user?.id);
      this.computedConnectionsList = this.connectionsList.filter(
        (e) => !usersId.includes(e.id)
      );
    }
  }

  render() {
    if (!this.channel) {
      return html`
        <app-shimmer class="title"></app-shimmer>
        <app-shimmer class="tiny"></app-shimmer>
        <app-shimmer class="image"></app-shimmer>
        <app-shimmer class="title tiny"></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer class="title tiny"></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
      `;
    }

    const { name, description, users, is_following } = this.channel;

    const userId = app.session.user.id;
    const my = users.find((item) => item?.user?.id === userId);
    const myRole = my?.role ?? "";

    return html`
      <flex-container class="justify-content-space-between" breakpoint="tiny">
        <flex-item><h1>${name}</h1></flex-item>
        <flex-item class="flex align-self-center ">
          <button
            type="button"
            class="button tiny outline align-content-end"
            ${ref(this.#followButtonRef)}
            @click=${() => this.#channelFollowToggle()}
          >
            ${is_following ? msg("Unfollow") : msg("Follow")}
          </button>
          ${this._allowedEdit && myRole === "owner"
            ? html`<a class="button tiny" href="/network/channels/${this.id}">
              <svg-icon icon="pencil"></svg-icon>
              ${msg("Edit")}
            </a>` 
          : nothing}
        </flex-item>
      </flex-container>
      ${description
        ? html`<p>${unsafeHTML(new Converter().makeHtml(description))}</p>`
        : nothing}
      ${this.#renderManageUsers(users)} ${this.#renderUsers()}
      ${this.#renderPostArea()} ${this.#renderPosts()}
    `;
  }

  #renderManageUsers(users) {
    const userId = app.session.user.id;
    const my = users.find((item) => item?.user?.id === userId);
    if (!my) return nothing;
    const myRole = my?.role ?? "";

    if (myRole !== "owner") return nothing;
    return html`
      <button
        class="white wide mb-small"
        @click=${() => (this.addUser = !this.addUser)}
      >
        <svg-icon icon="account-add"></svg-icon>
        ${msg("Add people to this channel", { desc: "Button label to add new user in channel." })}
      </button>

      ${this.addUser
        ? html`
            <section class="card">
              <h3>${msg("Add Users")}</h3>
              <form
                class="channel-add-new-user-form"
                ${ref(this.#addUserFormRef)}
              >
                <label>
                  <span data-label="">${msg("Users")}</span>
                  <connections-autocomplete
                    required
                    .userList=${this.computedConnectionsList}
                    @result-selected=${this.#onUserSelect}
                  ></connections-autocomplete>
                </label>
                <label>
                  <span data-label="">${msg("Role")}</span>
                  <select name="role" required ${ref(this.#roleSelectRef)}>
                    <option value="owner" selected>${msg("Owner")}</option>
                    <option value="editor">${"Editor"}</option>
                  </select>
                </label>
                <button type="button" @click=${this.#addSelectedUser}>
                  ${msg("Save changes")}
                </button>
              </form>
            </section>
          `
        : nothing}
    `;
  }

  #renderPostArea() {
    if (!this.channel) return nothing;

    const userId = app.session.user.id;
    const { users } = this.channel;
    const my = users.find((item) => item?.user?.id === userId);
    const myRole = my?.role ?? "";

    if (!["owner", "editor"].includes(myRole)) return nothing;
    return html`
      <section class="card">
        <form action="" method="post" ${ref(this.#editorFormRef)}>
          <rich-editor
            id="editor"
            ${ref(this.#contentEditorRef)}
            fixed-toolbar
            allow-files
            name="content"
            fileName="header_image"
            accept="image/*"
            placeholder="${msg('Type here to create a post...')}"
            value="${this.draft}"
            rows=${this.postEditorActivated ? 4 : 1}
            @click=${() => (this.postEditorActivated = true)}
            @input=${throttle(this.updateDraft.bind(this), 500)}
          >
          </rich-editor>
          <button
            type="button"
            class="small wide"
            name="publish"
            @click="${this.#postAction}"
          >
            ${msg("Publish post")}
          </button>
        </form>
      </section>
    `;
  }

  #renderUsers() {
    const { users } = this.channel;
    const userId = app.session.user.id;

    const sortedUsers = [...users.filter((u) => u.user)].sort((a, b) => {
      if (a.user.id === userId) return -1;
      if (b.user.id === userId) return 1;
      return a.user.firstname.localeCompare(b?.user?.firstname);
    });

    const computedUsers = sortedUsers.map((item) => {
      return {
        ...item.user,
        role: item.role,
      };
    });

    const my = users.find((item) => item?.user?.id === userId);
    if (!my) return nothing;
    const myRole = my?.role ?? "";

    const usersActions = [
      {
        name: "change-role",
        icon: "user-role",
        labelProvider: (item) =>
          `Make ${item.role === "owner" ? msg("Editor") : msg("Owner")}`,
      },
      { name: "remove", label: msg("Remove"), icon: "trash", theme: "red" },
    ];

    return html`
      <manage-users
        .heading=${msg("People in channel", { desc: "Section heading to show and manage all members of the channel." })}
        .users=${computedUsers}
        .actions=${usersActions}
        ?allowAction=${myRole === "owner"}
        .badgeSelectors=${["role"]}
        @action=${this.#onUsersAction}
      ></manage-users>
    `;
  }

  #renderPosts() {
    return html`
      <infinite-list
        ${ref(this.#infinityListRef)}
        .renderItem=${this.#renderPostItem.bind(this)}
        @scroll-end=${this.#onScrollEnd.bind(this)}
      >
      </infinite-list>
    `;
  }

  #renderPostItem(post, index) {
    const userId = app.session.user.id;
    const { users } = this.channel;
    const my = users.find((item) => item?.user?.id === userId);
    const myRole = my?.role ?? "";

    const postUserId = post?.user_id;
    const isMe = postUserId === userId;

    return html`<post-entry
      .index=${index}
      for="channel-detail"
      .post=${post}
      .postId=${post.id}
      .stats=${post.stats}
      ?editAllowed=${myRole === "owner" || isMe}
      @refresh=${this.#refreshPosts.bind(this)}
    ></post-entry>`;
  }

  async #postAction() {
    const content = this.#contentEditorRef.value.getFormValue();
    const imageFile = this.#contentEditorRef.value.files[0];

    if (!content || content.length <= 1) {
      return showAlert({
        title: msg("Post invalid"),
        message: msg("The post contents should not be empty", { desc: "Description text for invalid post content." }),
      });
    }

    const task = async () => {
      this.loading = true;

      let imageBase64;
      if (imageFile) {
        const fileSizeInBytes = imageFile.size;
        const fileSizeInMB = fileSizeInBytes / (1024 * 1024);

        if (fileSizeInMB > 10) {
          return showAlert({
            title: msg("Selected Image is too large", { desc: "Title for alert dialog to indicate user that selected image is too large."}),
            message: msg("Please upload an image smaller than 10MB.", { desc: "Description for alert dialog to indicate user that selected image is too large." }),
          });
        }
        try {
          const resizedImage = await resizeImage(imageFile, {
            quality: 0.7,
          });
          imageBase64 = resizedImage.url;
        } catch {
          imageBase64 = await imageToBase64(imageFile);
        }
      }

      const postData = {
        content,
        ...(imageBase64 && { header_image: imageBase64 }),
      };
      postData["channel_id"] = this.channel.id;
      const result = await this.#timelineDomain.publishPost(postData);

      if (!result) return;

      app.addToastMessage("Post has been published successfully", {
        type: "success",
      });

      // Reset content editor when successfully posted.
      this.#contentEditorRef.value.reset(1);
      this.postEditorActivated = false;

      this.clearDraft();

      if (!result) return;

      // Add to the current view.
      this.posts.unshift(result.data);
      this.#infinityListRef.value.addItems(this.posts, true);
      this.requestUpdate();

      setTimeout(async () => {
        await this.#refreshPosts();
      }, 1000);
    };

    return Task.run(task, {
      description: "Publishing post...",
      ghost: this.#contentEditorRef.value,
    });
  }

  get draft() {
    return localStorage.getItem("new-post-draft") || "";
  }
  updateDraft(e) {
    const value = e.target.value;
    if (value) localStorage.setItem("new-post-draft", value);
  }
  clearDraft() {
    localStorage.removeItem("new-post-draft");
  }
}
