import { ApiRequest } from "../shared/APIRequest";
import { AppDomainHandler } from "../shared/app-domain-handler";


export class GenericDomain extends AppDomainHandler {
  #api;
  #baseUrl;

  constructor(baseUrl) {
    super();
    this.#api = ApiRequest.factory();
    this.#baseUrl = baseUrl;
  }

  get api() {
    return this.#api;
  }

  setBaseUrl(baseUrl) {
    this.#baseUrl = baseUrl;
  }

  /**
   * Gives back the base URL for the CRUD logic.
   *
   * @abstract This method should be overridden.
   * @param {string} operation - The name of the operation.
   * @param {object} options - An optional object containing additional options.
   * @returns {String} the base URL in string format.
   * @throws {Error} - if the method is not implemented.
   */
  baseUrl(operation, options) {
    if (!operation) {
      throw new Error('Not implemented');
    }
    if (typeof this.#baseUrl === 'function') return this.#baseUrl(operation, options);
    return this.#baseUrl;
  }

  /**
   * Retrieves a list of data from the API. When the API endpoint enforces pagination, we will automatically use
   * pagination in our results.
   *
   * @param {Object} options - The options for the API request.
   * @param {String} options.sort - Sort on specific column.
   * @param {String} options.direction - Sort direction, can be either 'ASC' or 'DESC'.
   * @param {Number} options.per_page - Number of items per page.
   * @param {Number} options.page - Page number.
   * @param {String} options.filter - Filter parameter.
   * @param {String} options.search - Search parameter.
   * @returns {Promise<*>} - A promise that resolves with the retrieved data or an error message.
   */
  async list(options = {}) {
    const url = `${this.baseUrl('list', options)}${options && Object.keys(options).length > 0 ? `?${new URLSearchParams(options)}` : ''}`;
    return await this.#api.getData(url, options);
  }

  /**
   * Retrieve single entity detail by key given (ID, Primary Key or Slug, depending on the API implementation).
   *
   * @param {string} key
   * @param {Object} options
   * @param {Object} options.query Query parameters.
   * @returns {Promise<*>}
   */
  async show(key, options = {}) {
    const url = `${this.baseUrl('show', options)}/${key}${options && options.query && Object.keys(options.query).length > 0 ? `?${new URLSearchParams(options.query)}` : ''}`;
    return await this.#api.getData(url, options);
  }

  /**
   * Create method creates a new record in the database using the provided data.
   *
   * @param {Object} data - The data to be used to create the new record.
   * @param {Object} options - The options for creating the record. (optional)
   * @param {Object} options.query - Any optional query parameters to be used in the request (optional).
   * @returns {Promise} - A promise that resolves to the created record.
   */
  async create(data, options = {}) {
    const url = `${this.baseUrl('update')}${options && options.query && Object.keys(options.query).length > 0 ? `?${new URLSearchParams(options.query)}` : ''}`;
    return await this.#api.postData(url, data, options);
  }

  /**
   * Updates a resource identified by the given key with the provided data and options.
   *
   * @param {string} key - The key that identifies the resource to be updated.
   * @param {*} data - The data to be updated for the resource.
   * @param {Object} options - Optional parameters for the update operation.
   * @param {Object} options.query - Query parameters.
   * @returns {Promise<*>} - A Promise that resolves to the response data of the update operation.
   */
  async update(key, data, options = {}) {
    const url = `${this.baseUrl('update')}/${key}${options && options.query && Object.keys(options.query).length > 0 ? `?${new URLSearchParams(options.query)}` : ''}`;
    return await this.#api.putData(url, data, options);
  }

  /**
   * Updates a resource identified by the given key with the provided data and options.
   *
   * @param {string} key - The key that identifies the resource to be updated.
   * @param {*} data - The data to be updated for the resource.
   * @param {Object} options - Optional parameters for the update operation.
   * @param {Object} options.query - Query parameters.
   * @returns {Promise<*>} - A Promise that resolves to the response data of the update operation.
   */
  async updatePartial(key, data, options = {}) {
    const url = `${this.baseUrl('updatePartial')}/${key}${options && options.query && Object.keys(options.query).length > 0 ? `?${new URLSearchParams(options.query)}` : ''}`;
    return await this.#api.patchData(url, data, options);
  }

  /**
   * Deletes data from the server.
   *
   * @param {string} key - The key/identifier for the data to be deleted.
   * @param {Object} [data={}] - Additional data to be passed with the delete request.
   * @param {Object} [options={}] - Additional options for the delete request.
   * @param {Object} options.query - Query parameters.
   * @returns {Promise<*>} - Returns a promise that resolves with the response data from the server.
   */
  async destroy(key, data = {}, options = {}) {
    const url = `${this.baseUrl('destroy')}/${key}${options && options.query && Object.keys(options.query).length > 0 ? `?${new URLSearchParams(options.query)}` : ''}`;
    return await this.#api.deleteData(url, data, options);
  }
}
