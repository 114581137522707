import { msg, str } from "@lit/localize";
import {
  askConfirm,
  showAlert,
} from "@qogni-technologies/design-system/src/components/base/modal-dialog.js";
import { isMobile } from "@qogni-technologies/design-system/src/shared/common";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { LitElement, html, nothing } from "lit";
import { createRef, ref } from "lit/directives/ref.js";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { Converter } from "showdown";
import { AccountDomain } from "../../domain/account-domain";
import { PostDomain } from "../../domain/post-domain";
import { TimelineDomain } from "../../domain/timeline-domain";
import "./reactions-list-dialog";
import { config } from "../../qogni-app-config";
import { nativeWebShare } from "../common";
import "./edit-comment-dialog";
import "./edit-post-dialog";
import "./comment-list";

export class PostEntry extends LitElement {
  createRenderRoot() {
    return this;
  }

  #domain;
  #postDomain;
  #accountDomain;
  #commentRichEditorRef = createRef();
  #commentListRef = createRef();

  static get properties() {
    return {
      index: { type: Number },
      postId: { type: String },
      for: { type: String },

      // Timeline uses
      entry: { type: Object },

      // Post
      post: { type: Object },

      // Option
      option: { type: Object },

      // User
      user: { type: Object },

      // Statstics
      stats: { type: Object },

      showComments: { type: Boolean },
      comments: { type: Array },
      commentToPost: { type: Boolean },

      selfFetch: { type: Boolean, attribute: "self-fetch" },

      // For saved items page
      saveId: { type: String },
      unsave: { type: Boolean },

      // Edit content
      _postEditDialogOpened: { type: Boolean },
      editAllowed: { type: Boolean },

      _reactedUserListDialogOpened: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.#domain = new TimelineDomain();
    this.#postDomain = new PostDomain();
    this.#accountDomain = new AccountDomain();
    this.comments = [];
  }

  async connectedCallback() {
    super.connectedCallback();

    if (this.selfFetch && this.postId) {
      await this.#getPost();
    }
  }

  willUpdate(changeProps) {
    if (changeProps.has("entry") && this.entry) {
      this.post = this.entry.post;
      this.repost = this.entry.repost;
      this.option = this.entry.option;
    }

    if (changeProps.has("post") && this.post) {
      this.postId = this.post?.id;

      if (this.post?.stats) {
        this.stats = this.post.stats;
      }
    }

    if (this.post && this.post?.user) {
      this.user = this.post?.user;
    }

    if (this.repost && this.repost?.post?.user) {
      this.user = this.repost.post.user;
    }

    if (this.option && this.option?.user) {
      this.user = this.option.user;
    }
  }

  async #getPost() {
    const task = async () => {
      this.post = await this.#postDomain.getPost(this.postId);
    };

    await Task.run(task, {
      ghost: this,
      description: "Loading post...",
    });
  }

  firstUpdated() {
    super.firstUpdated();

    // Load comments for first post
    if (this.index === 0 && Number(this.stats?.comments) !== 0) {
      this.showComments = true;
    }
  }

  async #onShare(post) {
    const task = async () => {
      const { id, user } = post;
      const { firstname, lastname } = user;
      const { absoluteUrl } = config;
      const link = `${absoluteUrl}/posts/${id}`;
      const title = msg(str`${firstname} ${lastname}'s post on Qogni`, {
        desc: "Refers to a specific user's while sharing post.",
      });

      await nativeWebShare({ title, text: link });
    };

    Task.run(task, {
      ghost: this,
      global: false,
    });
  }

  async #onTipOfDayShare(option) {
    const task = async () => {
      const { name } = option;
      const text = msg(
        str`This tip is offered on the Qogni App, your personal Health App. Get started at ${"https://app.qogni.io"}`,
        {
          desc: "Message promoting the Qogni App while sharing tip of the days.",
        }
      );

      await nativeWebShare({ title: name, text });
    };

    Task.run(task, {
      ghost: this,
      global: false,
    });
  }

  async #onDelete(post) {
    const task = async () => {
      const userConfirmed = await askConfirm({
        title: msg("Delete Post?", {
          desc: "Confirmation dialog heading asking if the user wants to delete a post.",
        }),
        message: msg("Are you sure you want to delete your post?", {
          desc: "Confirmation message on dialog to delete the user's post.",
        }),
      });

      if (!userConfirmed) return;

      await this.#domain.deletePost(post.id);
      this.dispatchEvent(new CustomEvent("refresh"));
    };

    await Task.run(task, {
      ghost: this,
      description: "Deleting post...",
    });
  }

  async #likePost(e) {
    const typeCode = e.detail?.name;
    const type = e.detail?.code;

    const task = async () => {
      const oldLike = this.post?.like || this.repost?.post?.like;
      if (oldLike) {
        // Unlike
        this.stats[oldLike.type_code]--;
        if (this.post) this.post.like = null;
        if (this.repost?.post) this.repost.post.like = null;
      }
      if (!oldLike || oldLike.type !== type) {
        // Like
        const userId = this.post?.user_id || this.repost?.user_id;
        this.stats[typeCode]++;
        const like = {
          user_id: userId,
          type: type,
          type_code: typeCode,
        };
        if (this.post) this.post.like = like;
        if (this.repost?.post) this.repost.post.like = like;
      }
      this.requestUpdate();

      const postId = this.post?.id || this.repost?.post.id;
      await this.#domain.likePost(postId, type);
    };

    return Task.run(task, {
      ghost: this,
      description: "Like post",
    });
  }

  #refreshComments() {
    this.showComments = true;
    if (this.#commentListRef.value) {
      this.#commentListRef.value.refresh();
    }
  }

  async #addComment() {
    const task = async () => {
      const commentContent = this.#commentRichEditorRef.value.text;

      if (!commentContent) {
        return showAlert({
          title: msg("Please enter text for your comment!", {
            desc: "Prompt asking the user to input text for their comment.",
          }),
        });
      }

      const postId = this.post?.id || this.repost?.post?.id;
      await this.#domain.addComment(postId, commentContent);
      this.stats.comments++;

      this.#refreshComments();

      // close comment write section
      this.commentToPost = false;

      app.addToastMessage("Comment posted successfully!");
      this.requestUpdate();
    };

    await Task.run(task, {
      ghost: this,
      description: "Adding comment to post...",
    });
  }

  async #sharePost(e) {
    // Disable repost button
    const target = e.target;
    target.disabled = true;

    const postId = this.post?.id || this.repost?.post?.id;
    await this.#repostPost(postId);
    // todo: share action.
  }

  async #repostPost(postId) {
    const task = async () => {
      try {
        await this.#domain.repostPost(postId);
      } catch (e) {
        if (e.response?.code === 409)
          return app.addToastMessage("You already reposted this post before!", {
            type: "error",
          });
        return app.addToastMessage("Error with reposting post!", {
          type: "error",
        });
      }

      // TODO: Set repost flag in the entries locally.
    };
    return Task.run(task, {
      ghost: this,
      description: "Reposting post...",
    });
  }

  async #onSave(id, key) {
    const task = async () => {
      const res = await this.#accountDomain.saveItemToSavedList({ [key]: id });
      if (!res.status) return false;
      app.addToastMessage("Saved successfully.");
    };

    await Task.run(task, {
      ghost: this,
    });
  }

  async #onUnsave() {
    const task = async () => {
      const userConfirmed = await askConfirm({
        title: msg("Confirm Unsave", { desc: "Title for unsave dialog." }),
        message: msg(str`Are you sure you want to unsave this ${"post"}?`, {
          desc: "Description for Confirm Unsave dialog.",
        }),
        okText: msg("Confirm"),
      });
      if (!userConfirmed) return;

      await this.#accountDomain.unsaveItemToSavedList(this.saveId);
      this.dispatchEvent(new CustomEvent("refresh"));
    };

    Task.run(task, {
      ghost: this,
    });
  }

  #onEdit() {
    this._postEditDialogOpened = true;
  }

  #editComment(comment) {
    this._commentEditDialogOpened = true;
    this._editComment = comment;
  }

  async #onEditSuccess() {
    const task = async () => {
      await this.#getPost();
    };

    await Task.run(task, {
      ghost: this,
    });
  }

  render() {
    if (!this.post && !this.repost && !this.option) {
      return html`
        <section class="card">
          <app-shimmer class="title"></app-shimmer>
          <app-shimmer class="tiny"></app-shimmer>
          <app-shimmer class="mb"></app-shimmer>
          <app-shimmer class="image"></app-shimmer>
        </section>
      `;
    }

    if (this.post) return this.renderPostEntry(this.entry);
    if (this.repost) return this.renderPostEntry(this.entry);
    if (this.option) return this.renderTipOfTheDayEntry(this.entry);
    return nothing;
  }

  renderPostEntry(entry) {
    const post = this.post ?? this.repost.post;
    const isRepost = !!this.repost;
    const content = post.content;
    const image = post?.header_image_urls;
    const imageUrl = image?.jpg;

    if (!content) return nothing;

    let entryDate = null;
    let postDate = null;
    let entryDateFormatted = null;
    let postDateFormatted = null;
    if (entry?.created_at) {
      try {
        entryDate = new Date(entry.created_at);
        entryDateFormatted = Intl.DateTimeFormat(navigator.languages, {
          dateStyle: "short",
          timeStyle: "short",
        }).format(entryDate);
      } catch {
        console.warn("Can't parse date: " + entry.created_at);
        // ignore
      }
    }
    if (post.created_at) {
      try {
        postDate = new Date(post.created_at);
        postDateFormatted = Intl.DateTimeFormat(navigator.languages, {
          dateStyle: "short",
          timeStyle: "short",
        }).format(postDate);
      } catch {
        console.warn("Can't parse date (post): " + post.created_at);
        // ignore
      }
    }

    return html`
      ${isRepost
        ? html`
            <section class="card repost">
              <flex-container class="align-items-center">
                <flex-item>
                  <badge-tag class="yellow">${msg("Repost")}</badge-tag>
                </flex-item>

                <flex-item
                  class="time-ago"
                  title="${entryDateFormatted ?? "?"}"
                >
                  <time
                    datetime=${entryDate ?? ""}
                    data-update-interval="30000"
                  ></time>
                </flex-item>

                <flex-item>
                  <a href="/profile/${this.repost?.user_id}">
                    <article-author>
                      <profile-picture
                        name="${entry.repost.user.firstname} ${entry.repost.user
                          .lastname}"
                        img=${this.repost.user?.profile_img_url}
                        uuid=${this.repost.user_id}
                        link=${`/profile/${this.repost?.user_id}`}
                        size="34px"
                      >
                      </profile-picture>

                      ${entry.repost?.user
                        ? html`
                            <div class="info">
                              <strong
                                >${entry.repost.user.firstname}
                                ${entry.repost.user.lastname}</strong
                              >
                            </div>
                          `
                        : nothing}
                    </article-author>
                  </a>
                </flex-item>
              </flex-container>
            </section>
          `
        : nothing}
      <section
        class="card post ${isRepost ? "reposted" : ""}"
        data-comments=${this.stats?.comments ?? 0}
        data-id=${post.id}
        data-timeline-id="${entry?.id}"
      >
        <flex-container class="align-items-center">
          <flex-item>
            <badge-tag class="red">${msg("Post")}</badge-tag>
          </flex-item>

          <flex-item title="${postDateFormatted ?? "?"}">
            <time
              datetime="${new Date(postDate).toString()}"
              data-update-interval="30000"
            ></time>
          </flex-item>

          <nav id="account" data-dropdown class="post align-right img-button">
            <button title="Menu" class="simple">
              <svg-icon icon="meatballs"></svg-icon>
            </button>
            <menu>
              ${!this.unsave
                ? html`
                    <li>
                      <a @click=${() => this.#onSave(post.id, "post_id")}>
                        <svg-icon icon="save" size="20px"></svg-icon>
                        ${msg("Save")}
                      </a>
                    </li>
                  `
                : nothing}
              ${this.unsave
                ? html`
                    <li>
                      <a @click=${this.#onUnsave}>
                        <svg-icon icon="save" size="20px"></svg-icon>
                        ${msg("Unsave")}
                      </a>
                    </li>
                  `
                : nothing}
              ${this.editAllowed
                ? html`
                    <li>
                      <a @click=${this.#onEdit}>
                        <svg-icon icon="pencil" size="20px"></svg-icon>
                        ${msg("Edit")}
                      </a>
                    </li>
                  `
                : nothing}
              <li>
                <a @click=${() => this.#onShare(post)}>
                  <svg-icon
                    icon="${navigator.canShare ? "share" : "copy"}"
                    size="20px"
                  ></svg-icon>
                  ${navigator.canShare ? msg("Share") : msg("Copy Link")}
                </a>
              </li>
              ${this.editAllowed
                ? html`<li class="delete">
                    <a @click=${() => this.#onDelete(post)}
                      ><svg-icon icon="trash" size="20px"></svg-icon>${msg(
                        "Delete"
                      )}</a
                    >
                  </li>`
                : nothing}
            </menu>
          </nav>
        </flex-container>

        <article-author>
          <profile-picture
            name="${this.user?.firstname} ${this.user?.lastname}"
            img=${this.user?.profile_img_url}
            uuid=${this.post?.user_id || this.repost?.post?.user_id}
            link=${`/profile/${
              this.post?.user_id || this.repost?.post?.user_id
            }`}
            size="34px"
          >
          </profile-picture>

          ${this.user
            ? html`
                <div class="info">
                  ${msg("Posted by")}
                  <a
                    href="/profile/${this.post?.user_id ||
                    this.repost?.post?.user_id}"
                  >
                    <strong
                      >${this.user.firstname} ${this.user.lastname}</strong
                    >
                  </a>
                  ${this.for !== "channel-detail" &&
                  this.post?.channel &&
                  this.post?.channel?.name
                    ? html`
                        ${msg("in")}
                        <a
                          class="link"
                          href="/network/channels/${this.post.channel.id}"
                          ><strong>${this.post.channel.name}</strong></a
                        >
                      `
                    : nothing}
                  
                  ${this.for !== "group-detail" &&
                  this.post?.group &&
                  this.post?.group?.name
                    ? html`
                        ${msg("in")}
                        <a
                          class="link"
                          href="/network/groups/${this.post.group_id}/view"
                          ><strong>${this.post.group.name}</strong></a
                        >
                      `
                    : nothing}
                </div>
              `
            : nothing}
        </article-author>

        <translatable-content
          current-language="${post.language}"
          content-name="post"
        >
          ${unsafeHTML(
            new Converter({ openLinksInNewWindow: true }).makeHtml(content)
          )}
        </translatable-content>

        ${imageUrl
          ? html`
              <figure class="article-image">
                <img src="${imageUrl}" loading="lazy" alt="post image" />
              </figure>
            `
          : nothing}
        ${this.#renderSocialInteraction(post)}
      </section>
      ${this.#renderFragments()}
    `;
  }

  #renderFragments() {
    if (this._postEditDialogOpened) {
      return html`<edit-post-dialog
        open
        .post=${this.post}
        @close=${() => (this._postEditDialogOpened = false)}
        @success=${this.#onEditSuccess}
      ></edit-post-dialog>`;
    }

    if (this._reactedUserListDialogOpened) {
      return html`
        <reactions-list-dialog
          .postId=${this.repost?.post?.id || this.postId}
          .stats=${this.stats}
          @close=${() => (this._reactedUserListDialogOpened = false)}
        ></reactions-list-dialog>
      `;
    }

    return nothing;
  }

  #renderSocialInteraction(post) {
    if (!this.stats) return nothing;

    let lastEditedDate = null;
    let lastEditedDateFormatted = null;
    const editedAt = this.post?.edited_at || this.repost?.edited_at;
    console.log({editedAt});
    

    if (post.edited_at) {
      try {
        lastEditedDate = new Date(post.created_at);
        lastEditedDateFormatted = Intl.DateTimeFormat(navigator.languages, {
          dateStyle: "short",
          timeStyle: "short",
        }).format(lastEditedDate);
      } catch {
        console.warn("Can't parse date (post): " + post.edited_at);
        // ignore
      }
    }

    const likeSample = post?.like_sample ?? [];
    const computedLikeSample = likeSample.map((e) => {
      const firstname = e.user?.firstname;
      const lastname = e.user?.lastname;
      const img = e.user?.profile_img_url;
      const uuid = e.user?.id;
      const name = firstname + " " + lastname;
      return { name, uuid, img };
    });

    const like = this.stats?.like ?? 0;
    const love = this.stats?.love ?? 0;
    const idea = this.stats?.idea ?? 0;

    const totalReactions = like + love + idea;

    return html`
      <social-interaction>
        <flex-container
          class="align-items-center social-info justify-content-space-between force-row"
        >
          <flex-item
            class="rections"
            @click=${() => (this._reactedUserListDialogOpened = true)}
          >
            <user-stack .users=${computedLikeSample} size="20px"></user-stack>
            ${likeSample.length > 0
              ? html`<h6>
                  ${likeSample[0].user.firstname}
                  ${likeSample.length > 1
                    ? html`and ${totalReactions - 1} others`
                    : nothing}
                </h6>`
              : nothing}
          </flex-item>

          <div class="comment-btn" @click=${() => (this.showComments = true)}>
            ${this.stats?.comments ?? 0}
            ${this.stats?.comments > 1 || this.stats?.comments === 0
              ? msg("Comments")
              : msg("Comment", {
                  id: "comment-noun",
                  desc: "This singular form, noun.",
                })}
          </div>
        </flex-container>
        ${editedAt
          ? html`
              <div
                title="${lastEditedDateFormatted ?? "?"}"
                class="last-edited"
              >
                ${msg("Last edited at", {
                  desc: "Label indicating the timestamp of the last edit.",
                })}
                <time
                  datetime="${new Date(`${editedAt.replace(' ', 'T')}Z`).toString()}"
                  data-update-interval="30000"
                ></time>
              </div>
            `
          : nothing}
        ${this.#renderAddComments(post)} ${this.#renderComments()}
      </social-interaction>
    `;
  }

  renderTipOfTheDayEntry() {
    return html`
      <section class="card tip" data-timeline-id="${this.entry?.id}">
        <flex-container class="align-items-center">
          <flex-item>
            <badge-tag class="blue"
              >${msg("Qogni Science", {
                desc: "Badge label for tip of the days post.",
              })}</badge-tag
            >
          </flex-item>
          <flex-item>
            <nav id="account" data-dropdown class="post align-right img-button">
              <button title="Menu" class="simple">
                <svg-icon icon="meatballs"></svg-icon>
              </button>
              <menu>
                ${!this.unsave
                  ? html`
                      <li>
                        <a
                          @click=${() =>
                            this.#onSave(this.option.id, "option_id")}
                        >
                          <svg-icon icon="save}" size="20px"></svg-icon>
                          ${msg("Save")}
                        </a>
                      </li>
                    `
                  : nothing}
                ${this.unsave
                  ? html`
                      <li>
                        <a @click=${this.#onUnsave}>
                          <svg-icon icon="save}" size="20px"></svg-icon>
                          ${msg("Unsave")}
                        </a>
                      </li>
                    `
                  : nothing}
                <li>
                  <a @click=${() => this.#onTipOfDayShare(this.option)}>
                    <svg-icon
                      icon="${navigator.canShare ? "share" : "copy"}"
                      size="20px"
                    ></svg-icon>
                    ${navigator.canShare ? msg("Share") : msg("Copy Link")}
                  </a>
                </li>
              </menu>
            </nav>
          </flex-item>
        </flex-container>

        <translatable-content content-name="tip" current-language="en">
          <p class="mb-none">${this.option.name}</p>
        </translatable-content>
      </section>
    `;
  }

  #renderComments() {
    if (!this.showComments) return nothing;
    return html`<comment-list
      .postId=${this.post?.id || this.repost?.post?.id}
      .post=${this.post}
      ${ref(this.#commentListRef)}
    ></comment-list>`;
  }

  renderComment(comment) {
    const userId = app?.session?.user?.id;
    const commentUserId = comment?.user_id;
    const isMe = userId === commentUserId;

    return html`
      <section class="card comment">
        <comment-author>
          <flex-container>
            <flex-item>
              <profile-picture
                name="${comment.user?.firstname} ${comment.user?.lastname}"
                img="${comment.user?.profile_img_url}"
                uuid=${comment.user_id}
                link=${`/profile/${comment.user_id}`}
                size="20px"
              >
              </profile-picture>
            </flex-item>
            <flex-item>
              <strong
                >${comment.user?.firstname} ${comment.user?.lastname}</strong
              >
              ${msg("commented")}
              <span
                class="time-ago"
                title="${Intl.DateTimeFormat(navigator.languages, {
                  dateStyle: "short",
                  timeStyle: "short",
                }).format(new Date(comment.created_at))}"
              >
                <time
                  datetime="${new Date(comment.created_at).toString()}"
                  data-update-interval="30000"
                ></time>
              </span>
              ${isMe
                ? html`
                    <nav
                      id="account"
                      data-dropdown
                      class="comment align-right img-button"
                    >
                      <button title="Menu" class="simple">
                        <svg-icon icon="meatballs"></svg-icon>
                      </button>
                      <menu>
                        <li>
                          <a @click=${() => this.#editComment(comment)}>
                            <svg-icon icon="pencil" size="20px"></svg-icon>
                            ${msg("Edit")}
                          </a>
                        </li>
                      </menu>
                    </nav>
                  `
                : nothing}
            </flex-item>
          </flex-container>
        </comment-author>

        <comment-content>
          ${unsafeHTML(new Converter().makeHtml(comment.content))}
        </comment-content>
      </section>
    `;
  }

  #renderAddComments(post) {
    const isReposted = !!this.stats?.reposted;

    const reactionValue = () => {
      if (post.like?.type === 0) return "like";
      if (post.like?.type === 1) return "love";
      if (post.like?.type === 2) return "idea";
      return "";
    };
    return html`
      <flex-container class="social-controls align-items-stretch force-row">
        <flex-item>
          <post-reactions
            outline
            tiny
            value=${reactionValue()}
            @change=${this.#likePost}
          ></post-reactions>
        </flex-item>
        <flex-item class="">
          ${this.commentToPost
            ? html`
                <button class="outline tiny" @click=${this.#addComment}>
                  <svg-icon icon="comment"></svg-icon>
                  <span>
                    ${msg("Post comment", {
                      desc: "Button label to submit a comment.",
                    })}
                  </span>
                </button>
              `
            : html`
                <button
                  class="outline tiny"
                  @click=${() => (this.commentToPost = true)}
                >
                  <svg-icon icon="comment"></svg-icon>
                  <span> ${msg("Comment")} </span>
                </button>
              `}
        </flex-item>
        <flex-item>
          <button
            class="outline tiny ${isMobile() ? "round" : ""}"
            @click=${this.#sharePost.bind(this)}
            name="repost"
            ?disabled=${isReposted}
          >
            <svg-icon icon="repost"></svg-icon>
            ${isMobile() ? "" : msg("Repost")}
          </button>
        </flex-item>
      </flex-container>
      ${!this.commentToPost
        ? nothing
        : html`
            <comment-container>
              <post-rich-editor
                name="content"
                placeholder=${msg("Add a comment...", {
                  desc: "Placeholder text prompting the user to write a comment.",
                })}
                value=""
                rows="2"
                ${ref(this.#commentRichEditorRef)}
              >
              </post-rich-editor>
              <button class="small round" @click=${this.#addComment}>
                <svg-icon icon="send"></svg-icon>
              </button>
            </comment-container>
          `}
    `;
  }
}

customElements.define("post-entry", PostEntry);
