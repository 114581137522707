import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { html, nothing } from "lit";
import { createRef, ref } from "lit/directives/ref.js";
import { QuestionnairePage } from "../shared/questionnaire";
import {keyed} from "lit/directives/keyed.js";
import {msg, str} from '@lit/localize';

export class PageHealthcheck extends QuestionnairePage {
  #startButtonRef = createRef();

  constructor() {
    super();
    this.answers = [];
  }

  get categoryName() {
    return msg("HealthCheck");
  }

  get hasRewards() {
    return true;
  }

  get rewards() {
    return {
      20: {
        title: msg(str`You have completed ${20} questions!`, { desc: 'Title to show reward for completing certain questions in braincheck and healthcheck' }),
        text: msg(str`Keep it up. You’ve earned ${20} points already!`, { desc: 'Description to show reward for completing certain questions in braincheck and healthcheck'}),
        image: "/assets/img/balance.svg",
        rewardAmount: 20,
      },
      60: {
        title: msg(str`You have completed ${60} questions!`, { desc: 'Title to show reward for completing certain questions in braincheck and healthcheck' }),
        text: msg(str`Keep it up. You’ve earned ${60} points so far.`, { desc: 'Description to show reward for completing certain questions in braincheck and healthcheck'}),
        image: "/assets/img/balance.svg",
        rewardAmount: 60,
      },
      100: {
        title: msg(str`You have completed ${100} questions! Almost there!`, { desc: 'Title to show reward for completing 275 questions in braincheck and healthcheck' }),
        text: msg(str`You are in the final stretch. You Rock. Did you know that out of the 92% that completed the HealthCheck, 42% indicated that they are inspired to make a change`, { desc: 'Title to show reward for completing certain questions in braincheck and healthcheck' }),
        image: "/assets/img/balance.svg",
        rewardAmount: 100,
      },
    };
  }

  async connectedCallback() {
    await super.connectedCallback();
    Task.run(
      async () => {
        await this.initPromise;
        this.loading = false;
      },
      {
        ghost: this.#startButtonRef.value,
      }
    );
  }

  renderIntro() {
    return html`
      <section class="hero center hide-full-width">
        <h1>${msg("HealthCheck")}</h1>
      </section>

      <section class="card">
        <accordion-list>
          <details class="red" open>
            <summary>${msg("What is the HealthCheck?", { desc: 'Title for explaining what is healthcheck' })}</summary>
            ${msg(`The HealthCheck evaluates 24 key body systems, including liver
            function, gut health, and hormonal balance. By identifying
            high-priority areas, Qogni provides hyper-personalized content to
            offer actionable insights and improve your health.`, {desc: "description for What is the HealthCheck?"})}
          </details>
          <details class="yellow">
            <summary>${msg("How long does it take?", {desc: "Title for explaining how long questionnaire"})}</summary>
            ${msg(`The HealthCheck takes about 15 minutes to complete. You can pause
            and resume anytime; your progress will be saved.`, { desc: 'description for How long does healthcheck take?'})}
          </details>
          <details class="green">
            <summary>${msg("Can I skip questions?", {desc: "Title for explaining can I skip questions?"})}</summary>
            ${msg(`To ensure the accuracy of your assessment, it’s important to answer
            all questions. Individual responses are not saved, preserving
            your privacy.`, {desc: 'description for Can I skip questions?'})}
          </details>
          <details class="blue">
            <summary>${msg(`How important is the test?`, {desc: 'Title for explaining how important is the test?'})}</summary>
            ${msg(`The HealthCheck is essential for creating your personalized content
            on Qogni. It helps you understand critical health areas, such as
            cardiovascular function, immune system performance, and blood sugar
            regulation, forming the foundation for enhancing your well-being.`, { desc: 'description for `How important is the test? in the HealthCheck page'})}
          </details>
        </accordion-list>

        ${this.questionnaire
          ? html` <p>${msg(str`Resume your unfinished ${this.categoryName}.`)}</p> `
          : nothing}
        ${this.canStartNew !== false
          ? html`
              <button
                type="button"
                class="wide"
                ?disabled=${this.loading}
                ${ref(this.#startButtonRef)}
                @click=${this.startButtonClick}
              >
                ${this.questionnaire
                  ? html`${msg(str`Continue the ${this.categoryName}`, { desc: 'Button label to continue questionnaire (Healthcheck or Braincheck)' })}`
                  : html`${msg(str`Start the ${this.categoryName}`, {desc: 'button label for start questionnaire (Healthcheck or Braincheck)'})}`}
              </button>
            `
          : html`
              <p>${msg(str`You recently finished a ${this.categoryName}.`, {desc: 'short description explaining user finished questionnaire (Healthcheck or Braincheck)'})}</p>
              <a href="/results" class="button wide small">
                ${msg(str`Show results of last ${this.categoryName}`, {desc: 'button label to navigate result page for finished questionnaire (Healthcheck or Braincheck)'})}
              </a>
            `}
        ${this.hasFinishedBefore && this.canStartNew !== false
          ? html`
              <section class="card mt-tiny">
                <p>
                  ${msg(str`You previously finished another ${this.categoryName}. You can see your results below.`,
                    { desc: 'Message informing the user about their recently completed activity and providing a link to results.' })}
                </p>
                <a href="/results" class="button outline wide small">
                  ${msg(str`Show results of last ${this.categoryName}`,
                    {desc: 'button label to navigate result page for finished questionnaire (Healthcheck or Braincheck)'}
                  )}
                </a>
              </section>
            `
          : nothing}
      </section>
    `;
  }

  renderCardComponent() {
    const answeredQuestions = this.questions.filter(question => question.answered === true);
    const numberOfAnsweredQuestions = answeredQuestions.length;
    return html`
      ${keyed(this.renderKey, html`
        <multi-answers
          offset=${numberOfAnsweredQuestions}
          .questions=${this.questions}
          @answer=${this.answer}
          @undo=${this.undo}
          quick-answer
          can-undo
          ?undo-disabled=${!this.undoDetails}>
        </multi-answers>
      `)}
    `;
  }

  renderWidget() {
    this.setAttribute("hidden", "");
    return nothing;
  }
}
