import { ApiRequest } from "../shared/APIRequest";
import { AppDomainHandler } from "../shared/app-domain-handler";
import { removeFalsyValues } from "@qogni-technologies/design-system/src/shared/common";

export class FutureSelfDomain extends AppDomainHandler {
  #api;

  constructor() {
    super();

    this.#api = ApiRequest.factory();
  }

  async getSessions(options) {
    let result;
    options = removeFalsyValues(options);
    try {
      const query = {
        page: 1,
        ...(options && { ...options }),
      };
      let url = `/users/me/future-self/sessions?${new URLSearchParams(query)}`;

      result = await this.#api.getData(url);
    } catch (err) {
      console.error("Error on getSessions: ", err);
      return false;
    }

    if (!result.status) return false;
    return result;
  }

  async getMessages(sessionId, options) {
    let result;
    options = removeFalsyValues(options);
    try {
      const query = {
        ...(sessionId && {chatbot_session_id: sessionId}),
        ...(options && { ...options }),
      };
      let url = `/users/me/future-self/messages?${new URLSearchParams(query)}`;

      result = await this.#api.getData(url);
    } catch (err) {
      console.error("Error on getSessions: ", err);
      return false;
    }

    if (!result.status) return false;
    return result;
  }

  async createNewChatBotSession() {
    return await this.#api.postData(`/users/me/future-self/sessions`);
  }
}
