import { html } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { AuthenticatedMixin, WidgetEnabledPWAPage } from "../shared/pwa-page";
import { msg } from "@lit/localize";

const data = {
  new: [
    {
      title: "AI-buddy improvements",
      description: "We continue to make the AI-buddy more personalized. Keep hanging on, soon you will be able to see your history.",
    },
    {
      title: "Improve notifications",
      description: "We improved the notification system.",
    },
  ],
  cooking: [
    {
      title: "AI-buddy history",
      description:
        "See your previous conversations with the AI-buddy.",
    },
    {
      title: "Video calling with your Coach",
      description:
        "Call with your coach directly on our platform.",
    },
  ],
};

export class PageWhatsNew extends AuthenticatedMixin(WidgetEnabledPWAPage) {
  renderPage() {
    return this.renderItems();
  }

  getWidgetSettings() {
    return {
      ...(super.getWidgetSettings() ?? {}),
      priority: 900,
      title: msg('New and Upcoming', {desc: 'Label for widget that shows new and upcoming features.'}),
      cta: {
        href: "/whats-new",
      },
    };
  }

  renderWidget() {
    return html`
      <section class="card">
        <h3>New Qogni Features</h3>
        <ul class="mb-small">
          ${repeat(data.new, (item) => {
            return html`<li>${item.title}</li>`;
          })}
        </ul>
      </section>
    `;
  }

  get title(){
    return "What's New"
  }

  renderItems(compact) {
    return html`

      <section class="card">
        <h3>${msg('New Qogni Features')}</h3>
        <ul class="mb-small">
          ${repeat(data.new, (item) => this.renderItem(item, compact))}
        </ul>
      </section>

      <section class="card">
        <h3>
          ${compact
            ? html`${msg('Coming in the next releases')}`
            : html`${msg("Check out what's coming in the next releases")}`}
        </h3>
        <ul>
          ${repeat(data.cooking, (item) => this.renderItem(item, compact))}
        </ul>
      </section>
    `;
  }

  renderItem(item, compact) {
    return html`<li>
      ${item.title} ${compact ? "" : html`<small>${item.description}</small>`}
    </li>`;
  }
}
