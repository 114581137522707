import {
  askConfirm,
  showAlert,
} from "@qogni-technologies/design-system/src/components/base/modal-dialog.js";
import {
  imageToBase64,
  throttle,
} from "@qogni-technologies/design-system/src/shared/common";
import { resizeImage } from "@qogni-technologies/pwa-utils-library/src/utils/resize-image";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { html, nothing } from "lit";
import { createRef, ref } from "lit/directives/ref.js";
import { repeat } from "lit/directives/repeat.js";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { Converter } from "showdown";
import { GroupDomain } from "../../../domain/group-domain";
import { TimelineDomain } from "../../../domain/timeline-domain";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  PWAPage,
} from "../../../shared/pwa-page";
import "../../../shared/web-components/manage-users";
import { msg, str } from '@lit/localize';

export class PageSingleGroup extends OnboardedMixin(
  AuthenticatedMixin(PWAPage)
) {
  #domain;
  #timelineDomain;

  #postsCurrentPage = 1;
  #postLastPage;
  #postPagination;
  #postsPerPage = 5;

  #postFullList = [];

  #editorFormRef = createRef();
  #contentEditorRef = createRef();

  static get properties() {
    return {
      id: { type: String, routeOrigin: "pathname" },
      group: { type: Object },
      users: { type: Array },
      addUser: { type: Boolean },

      posts: { type: Array },
      _computedPostList: { type: Array },

      postEditorActivated: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.#domain = new GroupDomain();
    this.#timelineDomain = new TimelineDomain();

    this.addUser = false;

    this._computedPostList = [];
  }

  async connectedCallback() {
    await super.connectedCallback();

    if (this.id) {
      await this.#getDetails();

      const { is_member } = this.group;
      if (is_member) {
        await this.#getUsers();
        await this.#getPosts();
      }
    }
  }

  async #getDetails() {
    return await Task.run(
      async () => {
        const res = await this.#domain.detail(this.id);
        if (res) {
          this.group = res.data;
        }
      },
      {
        ghost: this,
        description: "Loading group details...",
      }
    );
  }

  async #getUsers() {
    return await Task.run(
      async () => {
        const res = await this.#domain.users(this.id);
        if (res) {
          this.users = res.data;
        }
      },
      {
        ghost: this,
        description: "Loading group users...",
      }
    );
  }

  async #groupFollowToggle() {
    const task = async () => {
      const { id, is_member } = this.group;
      if (is_member) {
        const userConfirmed = await askConfirm({
          title: "Please confirm?",
          message: `Are you sure you want to leave the group?`,
          okText: "Confirm",
        });

        if (!userConfirmed) return;
        await this.#domain.leave(id);
      } else {
        await this.#domain.join(id);
      }

      await this.#getDetails();
    };

    await Task.run(task, {
      ghost: this,
    });
  }

  async #onChangeRole(item, role) {
    const task = async () => {
      const { id, firstname, lastname, accepted_at } = item;
      if (!accepted_at) {
        const userConfirmed = await askConfirm({
          title: msg("Change role?", {desc: "To notify user to change role of other user."}),
          message: msg(str`Are you sure you want to accept ${firstname} ${lastname}?`, { desc: "Confirmation prompt to accept a user by their first and last name." }),
          okText: msg("Accept"),
        });
        if (!userConfirmed) return;

        await this.#domain.acceptDenyUser(this.id, id, true);
      } else {
        const newRole = role === "owner" ? "member" : "owner";

        const userConfirmed = await askConfirm({
          title: msg("Change role?", {desc: "To notify user to change role of other user."}),
          message: msg(str`Are you sure you want to change role of ${firstname} ${lastname} to ${newRole}?`, { desc: "Confirmation prompt to change the user's role." }),
          okText: msg("Confirm"),
        });
        if (!userConfirmed) return;

        await this.#domain.changeRole(this.id, id, newRole);
      }

      await this.#getUsers();
    };

    await Task.run(task, {
      ghost: this,
      description: "changing role...",
    });
  }

  async #onRemoveUser(item) {
    const task = async () => {
      const { id, firstname, lastname, accepted_at } = item;
      const userConfirmed = await askConfirm({
        title: msg("Remove User?", { desc: "Confirmation heading when user want to remove specific user."}),
        message: msg(str`Are you sure you want to remove ${firstname} ${lastname} from group?`, { desc: "Confirmation prompt to remove a user from the group." }),
        okText: msg("Remove"),
      });
      if (!userConfirmed) return;

      if (!accepted_at) {
        await this.#domain.acceptDenyUser(this.id, id, false);
      } else {
        await this.#domain.removeUser(this.id, id);
      }
      // await this.#domain.removeUser(this.id, id);
      await this.#getUsers();
    };

    await Task.run(task, {
      ghost: this,
      description: "Removing user from group...",
    });
  }

  #onUsersAction(e) {
    const { action, user } = e.detail;
    const { role } = user;

    switch (action) {
      case "change-role":
        this.#onChangeRole(user, role);
        break;
      case "remove":
        this.#onRemoveUser(user);
        break;
      default:
        break;
    }
  }

  async #postAction() {
    const content = this.#contentEditorRef.value.getFormValue();
    const imageFile = this.#contentEditorRef.value.files[0];

    if (!content || content.length <= 1) {
      return showAlert({
        title: msg("Post invalid"),
        message: msg("The post contents should not be empty", { desc: "Description text for invalid post content." }),
      });
    }

    const task = async () => {
      this.loading = true;

      let imageBase64;
      if (imageFile) {
        const fileSizeInBytes = imageFile.size;
        const fileSizeInMB = fileSizeInBytes / (1024 * 1024);

        if (fileSizeInMB > 10) {
          return showAlert({
            title: msg("Selected Image is too large", { desc: "Title for alert dialog to indicate user that selected image is too large."}),
            message: msg("Please upload an image smaller than 10MB.", { desc: "Description for alert dialog to indicate user that selected image is too large." }),
          });
        }
        try {
          const resizedImage = await resizeImage(imageFile, {
            quality: 0.7,
          });
          imageBase64 = resizedImage.url;
        } catch {
          imageBase64 = await imageToBase64(imageFile);
        }
      }

      const postData = {
        content,
        ...(imageBase64 && { header_image: imageBase64 }),
      };
      postData["group_id"] = this.group.id;
      const result = await this.#timelineDomain.publishPost(postData);

      if (!result) return;

      app.addToastMessage("Post has been published successfully", {
        type: "success",
      });

      // Reset content editor when successfully posted.
      this.#contentEditorRef.value.reset(1);
      this.postEditorActivated = false;

      this.clearDraft();

      if (!result) return;

      // Add to the current view.
      this._computedPostList.unshift(result.data);
      this.requestUpdate();

      setTimeout(async () => {
        await this.#refreshPosts();
      }, 1000);
    };

    return Task.run(task, {
      description: "Publishing post...",
      ghost: this.#contentEditorRef.value,
    });
  }

  async #getPosts() {
    const task = async () => {
      const options = {
        per_page: this.#postsPerPage,
        page: this.#postsCurrentPage,
      };
      const response = await this.#domain.posts(this.id, options);
      this.posts = response?.data;
      this.#postPagination = response?.pagination;
      this.#postLastPage = this.#postPagination?.last_page;
    };

    await Task.run(task);
  }

  async #onRangeChanged(e) {
    const { last } = e;
    if (last > this.#postsCurrentPage * 25) {
      this.#postsCurrentPage++;
      if (
        this.#postsCurrentPage === 1 ||
        this.#postsCurrentPage <= this.#postLastPage
      ) {
        await this.#getPosts();
      }
    }
  }

  async #refreshPosts() {
    this.#postsCurrentPage = 1;
    await this.#getPosts();
  }

  get draft() {
    return localStorage.getItem("new-post-draft") || "";
  }

  updateDraft(e) {
    const value = e.target.value;
    if (value) localStorage.setItem("new-post-draft", value);
  }

  clearDraft() {
    localStorage.removeItem("new-post-draft");
  }

  willUpdate(changeProps) {
    if (changeProps.has("posts")) {
      this.#postFullList = [...this.#postFullList, ...this.posts];
      const numOfPosts = this.#postFullList.length;
      const { total } = this.#postPagination;
      const numOfShimmer = total - numOfPosts;

      const shimmerList = Array.from({ length: numOfShimmer }, () => undefined);
      this._computedPostList = [...this.#postFullList, ...shimmerList];
    }
  }

  render() {
    if (!this.group) {
      return html`
        <app-shimmer class="title"></app-shimmer>
        <app-shimmer class="tiny"></app-shimmer>
        <app-shimmer class="image"></app-shimmer>
        <app-shimmer class="title tiny"></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer class="title tiny"></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
      `;
    }

    const {
      name,
      description,
      is_admin,
      is_member,
      requested_membership,
      type,
    } = this.group;

    return html`
      <flex-container class="justify-content-space-between" breakpoint="tiny">
        <flex-item class="grow-1"><h1>${name}</h1></flex-item>
        ${is_admin
          ? html`<flex-item class="align-self-center">
              <a
                class="button tiny outline align-content-end"
                href="/network/groups/${this.id}"
              >
                ${msg("Edit")}
              </a>
            </flex-item>`
          : nothing}
        ${!requested_membership
          ? is_admin
            ? nothing
            : html`<flex-item class="align-self-center">
                <button
                  type="button"
                  class="button tiny outline align-content-end"
                  @click=${() => this.#groupFollowToggle()}
                >
                  ${is_member ? msg("Leave") : type === "open" ? msg("Join") : msg("Request")}
                </button>
              </flex-item>`
          : html`<flex-item class="align-self-center">
              <badge-tag>${msg("Requested")}</badge-tag>
            </flex-item>`}
      </flex-container>
      ${description
        ? html`<p>${unsafeHTML(new Converter().makeHtml(description))}</p>`
        : nothing}
      ${this.#renderUsers()} ${this.#renderCreatePostArea()}
      ${this.#renderPosts()}
    `;
  }

  #renderUsers() {
    const { is_member } = this.group;
    if (!is_member || !this.users) return nothing;

    const computedUsers = structuredClone(this.users)
      .map((item) => {
        const user = item.user;
        delete item.user;

        let badge;

        if (item.role === "member" && !item.accepted_at) {
          badge = msg("Requested");
        }

        return {
          ...item,
          ...user,
          ...(badge && { badge }),
        };
      })
      .filter((item) => !item.denied_at);

    const usersActions = [
      {
        name: "change-role",
        icon: "user-role",
        labelProvider: (item) => {
          if (item.role === "member" && !item.accepted_at) return msg(`Accept`);
          return `${msg("Make")} ${item.role === "owner" ? msg("Member") : msg("Owner")}`;
        },
      },
      {
        name: "remove",
        icon: "trash",
        theme: "red",
        labelProvider: (item) => {
          if (item.role === "member" && !item.accepted_at) return msg("Deny");
          return msg("Remove");
        },
      },
    ];

    const { is_admin } = this.group;
    return html`
      <manage-users
        .heading=${msg("People in group", { desc: "Section heading to show and manage all members of the group." })}
        .users=${computedUsers}
        .actions=${usersActions}
        ?allowAction=${is_admin}
        .badgeSelectors=${["role", "badge"]}
        @action=${this.#onUsersAction}
      ></manage-users>
    `;
  }

  #renderCreatePostArea() {
    const { is_member } = this.group;
    if (!is_member) return nothing;

    return html`
      <section class="card">
        <form action="" method="post" ${ref(this.#editorFormRef)}>
          <rich-editor
            id="editor"
            ${ref(this.#contentEditorRef)}
            fixed-toolbar
            allow-files
            name="content"
            fileName="header_image"
            accept="image/*"
            placeholder="${msg('Type here to create a post...')}"
            value="${this.draft}"
            rows=${this.postEditorActivated ? 4 : 1}
            @click=${() => (this.postEditorActivated = true)}
            @input=${throttle(this.updateDraft.bind(this), 500)}
          >
          </rich-editor>
          <button
            type="button"
            class="small wide"
            name="publish"
            @click="${this.#postAction}"
          >
            ${msg("Publish post")}
          </button>
        </form>
      </section>
    `;
  }

  #renderPosts() {
    if (this.#postFullList.length === 0) {
      if (this.group) return nothing;

      const shimmerList = Array.from({ length: 5 }, () => ({}));
      return html`
        ${repeat(
          shimmerList,
          () => html`<general-shimmer type="post"></general-shimmer>`
        )}
      `;
    }

    return html`
      <lit-virtualizer
        .items=${this._computedPostList}
        .renderItem=${this.#renderPostListItem.bind(this)}
        @rangeChanged=${this.#onRangeChanged}
      ></lit-virtualizer>
    `;
  }

  #renderPostListItem(post, index) {
    if (!post) return html`<general-shimmer type="post"></general-shimmer>`;

    const userId = app.session.user.id;
    const { is_admin } = this.group;

    const postUserId = post?.user_id;
    const isMe = postUserId === userId;

    return html`
      <post-entry
        .index=${index}
        for="group-detail"
        .post=${post}
        .postId=${post.id}
        .stats=${post.stats}
        ?editAllowed=${is_admin || isMe}
        @refresh=${this.#refreshPosts.bind(this)}
      >
      </post-entry>
    `;
  }
}
