import { html, nothing } from "lit";

import {
  AuthenticatedMixin,
  OnboardedMixin,
  WidgetEnabledPWAPage,
} from "../../shared/pwa-page";
import "./share";
import { msg, str } from "@lit/localize";
import { unsafeHTML } from "lit/directives/unsafe-html.js";

export class PageBenefits extends OnboardedMixin(
  AuthenticatedMixin(WidgetEnabledPWAPage)
) {
  renderPage() {
    return html`
      ${this.renderPoints()}
      <section class="card">${this.renderShareAndBenefit()}</section>
    `;
  }

  getWidgetSettings() {
    return {
      ...super.getWidgetSettings(),
      priority: 650,
      title: msg('Share and benefit'),
      cta: {
        href: '/benefits/share'
      }
    };
  }

  renderWidget() {
    return html`
      <section class="card">
        ${this.renderShareAndBenefit(true)}
      </section>
    `;
  }

  static renderShareIntro() {
    return html`<p>
      ${msg(`Invite people to Qogni and earn`)} <a title="Go to Benefit Centre" href="/benefits"><strong>Qogni Points</strong></a>, ${msg('which you can spend the points on all kinds of benefits')}.
    </p>`;
  }

  /**
   * @param {Boolean} compact
   */
  renderPoints(compact) {
    const currentPoints = parseInt(app.session.user?.current_points) ?? 0;
    const currentLevel = app.session.user?.current_point_level ?? {
      name: "unknown",
    };
    const nextLevel = app.session.user?.next_point_level;
    if (compact)
      return html`<section class="card flex">
        <img src="/assets/img/points.svg" alt="Points" loading="lazy" />
        <strong class="flex"
          >${msg(str`You have ${currentPoints} points... (${currentLevel.name})`)}
          <a title="${msg('Go to Benefit Centre')}" href="/benefits"
            ><svg-icon
              icon="info"
              size="32px"
              color="var(--color-accent-300)"
              style="display: inline"
            ></svg-icon></a
        ></strong>
      </section>`;

    return html`
      <section class="card">
        <points-level>
          <div class="points">
            <div class="wrap">
              <figure>
                <img src="/assets/img/points.svg" alt="Points" loading="lazy" />
              </figure>

              <div class="info">
                <h2>${msg(str`You have ${currentPoints} points`)}</h2>
                <p>${msg('That means that you are at this moment')}</p>
              </div>
            </div>

            <div class="points-level">
              <div class="wrap">
                <h4>${currentLevel?.name ?? "0"}</h4>

                <span class="score">${parseInt(nextLevel?.points) ?? "0"}</span>
              </div>

              <progress-indicator
                type="line"
                theme="yellow"
                max="${nextLevel?.points ??
                (currentPoints > 0 ? currentPoints * 2 : 50)}"
                value="${currentPoints > 0 ? currentPoints : 10}"
              ></progress-indicator>

              <h5 class="next-level">
                <strong>${nextLevel?.name ?? "Level 1"}</strong>
              </h5>
            </div>
          </div>
        </points-level>
      </section>
    `;
  }

  renderShareAndBenefit(isWidget) {
    return html`
      ${isWidget ? nothing : html`<img src="/assets/img/hands-graphic.svg" />`}
      ${PageBenefits.renderShareIntro()}

      <a
        title="${msg('Earn points by inviting others to Qogni!')}"
        class="button tiny outline"
        href="/benefits/share">
        ${msg('Spread the word!')}
      </a>
    `;
  }
}
