import "@markai/virtualizer";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { html, nothing } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { AccountDomain } from "../../domain/account-domain";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  PWAPage,
} from "../../shared/pwa-page";
import { msg, str } from "@lit/localize";

export class PageFollowing extends OnboardedMixin(AuthenticatedMixin(PWAPage)) {
  #domain;
  #currentPage = 1;
  #lastPage;
  #pagination;

  #fullList = [];

  static get properties() {
    return {
      activeTab: { type: Number },
      usersList: { type: Array },

      _computedUsersList: { type: Array },
    };
  }

  constructor() {
    super();
    this.#domain = new AccountDomain();
    this.activeTab = "following";

    this._computedUsersList = [];

    this.tabs = [
      { name: "following", label: msg("Following") },
      { name: "followers", label: msg("Followers") },
      { name: "connections", label: msg("Connections") },
    ];
  }

  connectedCallback() {
    super.connectedCallback();

    if (window.location.hash) {
      this.activeTab = window.location.hash.substring(1);
    }

    this.#fetch();
  }

  async #fetch() {
    const task = async () => {
      if (this.activeTab === "following") {
        const res = await this.#domain.getFollowing(this.#currentPage);
        this.usersList = res.data;
        this.#pagination = res?.pagination;
        this.#lastPage = res?.pagination?.last_page;
      } else if (this.activeTab === "followers") {
        const res = await this.#domain.getFollowers(this.#currentPage);
        this.usersList = res.data;
        this.#pagination = res?.pagination;
        this.#lastPage = res?.pagination?.last_page;
      } else if (this.activeTab === "connections") {
        const res = await this.#domain.getConnections({
          page: this.#currentPage,
        });
        this.usersList = res.data;
        this.#pagination = res?.pagination;
        this.#lastPage = res?.pagination?.last_page;
      }
    };

    await Task.run(task);
  }

  render() {
    return html`
      <section class="hero center">
        <h1>${msg("Following")} / ${msg("Followers")}</h1>
      </section>

      ${this.#renderTabs()} ${this.#renderList()}
    `;
  }

  #renderTabs() {
    
    return html`
      <tabs-indicator
        .tabs=${this.tabs}
        value=${this.activeTab}
        @change=${this.#onTabChange}
      ></tabs-indicator>
    `;
  }

  #renderList() {
    if (this.#pagination && this.#pagination?.total === 0) {
      const emptyStateMessage =
        this.activeTab === "followers" || this.activeTab === "following"
          ? msg("Follow those who inspire positivity and support your journey to growth", { desc: "Empty followers list page; Encouragement message to follow individuals who motivate and align with personal growth." })
          : this.activeTab === "connections"
          ? msg("Connecting with inspiring people boosts healthy lifestyle adoption by 83%. Start building your network", { desc: "Empty connections list page; Motivational message highlighting the benefits of connecting with inspiring people for a healthier lifestyle." })
          : nothing;
      
      const activeTabName = this.tabs.find(tab => this.activeTab === tab.name).label;
      return html`
        <section class="card empty-state">
          <h2>${msg(str`No ${activeTabName} found`)}</h2>
          <p>${emptyStateMessage}</p>
        </section>
      `;
    }

    if (this.#fullList.length === 0) {
      const shimmerList = Array.from({ length: 5 }, () => ({}));
      return html`
        ${repeat(shimmerList, () => {
          return html`
            <single-connection>
              <app-shimmer class="image circle"></app-shimmer>
              <div class="shimmer-flex">
                <app-shimmer></app-shimmer>
                <app-shimmer class="tiny"></app-shimmer>
              </div>
            </single-connection>
          `;
        })}
      `;
    }

    return html`
      <lit-virtualizer
        .items=${this._computedUsersList}
        .renderItem=${this.#renderItem.bind(this)}
        @rangeChanged=${this.#onRangeChanged}
      ></lit-virtualizer>
    `;
  }

  #renderItem(u) {
    if (!u) {
      return html`
        <div class="single-connection-wrapper">
          <single-connection>
            <app-shimmer class="image circle"></app-shimmer>
            <div class="shimmer-flex">
              <app-shimmer></app-shimmer>
              <app-shimmer class="tiny"></app-shimmer>
            </div>
          </single-connection>
        </div>
      `;
    }

    const {
      id,
      slug,
      firstname,
      lastname,
      profile_img_url,
      badges,
      followers_count,
      following_count,
      has_connection,
    } = u;

    return html`
      <div class="single-connection-wrapper">
        <single-connection data-id="${id}">
          <profile-picture
            name="${firstname} ${lastname}"
            img="${profile_img_url}"
            uuid="${id}"
            link=${`/profile/${slug || id}`}
            size="56px"
          >
          </profile-picture>

          <div class="info">
            <a href="/profile/${slug ?? id}">
              <h4>${firstname} ${lastname}</h4>
              ${badges
                ? html`
                    ${repeat(
                      badges,
                      (b) => html`
                        <badge-tag class="small blue filled">
                          <svg-icon icon="crown"></svg-icon> ${b.name}
                        </badge-tag>
                      `
                    )}
                  `
                : nothing}
            </a>

            <div class="group">
              <div class="related">
                <svg-icon size="16px" icon="link"></svg-icon>
                <span class="count"> ${followers_count} </span>
              </div>

              <div class="connections">
                <svg-icon size="16px" icon="account-add"></svg-icon>
                <span class="count"> ${following_count} </span>
              </div>
            </div>
          </div>

          <div class="controls">
            ${this.activeTab === "following"
              ? html`
                  <button
                    class="beige tiny"
                    type="button"
                    @click=${(e) => this.#unfollowUser(e, u)}
                  >
                    <svg-icon size="16px" icon="minus"></svg-icon>
                    ${msg("Unfollow")}
                  </button>
                `
              : nothing}
            ${this.activeTab === "followers" && !has_connection
              ? html`
                  <button
                    class="beige tiny"
                    type="button"
                    @click=${() => this.#followUser(u)}
                  >
                    <svg-icon size="16px" icon="plus"></svg-icon>
                    ${msg("Follow back")}
                  </button>
                `
              : nothing}
          </div>
        </single-connection>
      </div>
    `;
  }

  async #refreshList() {
    this.#currentPage = 1;
    this.#fullList = [];
    await this.#fetch();
  }

  async #followUser(user) {
    const target = event.target;
    const singleConnection = target.closest("single-connection");

    const task = async () => {
      const { id } = user;
      await this.#domain.followUser(id);
      await this.#refreshList();
    };

    await Task.run(task, {
      ghost: singleConnection,
    });
  }

  async #unfollowUser(event, user) {
    const target = event.target;
    const singleConnection = target.closest("single-connection");

    const task = async () => {
      const { id } = user;
      await this.#domain.unfollowUser(id);
      await this.#refreshList();
    };

    await Task.run(task, {
      ghost: singleConnection,
    });
  }

  async #onTabChange(e) {
    this.activeTab = e.detail.name;
    window.location.hash = `#${this.activeTab}`;

    this.#pagination = undefined;
    this.#currentPage = 1;
    this.#fullList = [];
    await this.#fetch();
  }

  async #onRangeChanged(e) {
    const { last } = e;
    if (last < this.#currentPage * 25) return;
    this.#currentPage++;
    if (this.#currentPage === 1 || this.#currentPage <= this.#lastPage) {
      await this.#fetch();
    }
  }

  willUpdate(changeProps) {
    if (changeProps.has("usersList")) {
      this.#fullList = [...this.#fullList, ...this.usersList];
      const numOfRecipes = this.#fullList.length;
      const { total } = this.#pagination;
      const numOfShimmer = total - numOfRecipes;

      const shimmerList = Array.from({ length: numOfShimmer }, () => undefined);
      this._computedUsersList = [...this.#fullList, ...shimmerList];
    }
  }
}
