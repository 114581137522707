import { html, nothing } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { AccountDomain } from "../../domain/account-domain";
import { LightElement } from "../common";
import { MasterDataDoamin } from "../../domain/master-data-domain";

/**
 * Autocomplete element for Jobs
 */
customElements.define(
  "health-settings",
  class HealSettings extends LightElement {
    #domain = AccountDomain.singleton;

    static get properties() {
      return {
        diet: { type: String },
        topics: { type: Array },
        allergies: { type: Array },
        ready: { type: Boolean },
      };
    }

    constructor() {
      super();
      this.#domain = AccountDomain.singleton;
    }

    connectedCallback() {
      super.connectedCallback();

      this.load();
    }

    async load() {
      this.diet = await this.#domain.getDietName();

      this.allergies = await this.#domain.getAllergiesAndIntolerances();
      const user = app.session.user;
      const topics = user?.interest_topics ?? [];
      const masterTopics = await MasterDataDoamin.singleton.getInterestTopics();
      const filteredTopics = masterTopics.filter(i => topics.find(e => e.id === i.id))
      const currentLanguage = app.session.user?.language;
      
      this.topics = filteredTopics.map(e => {
        const traslatedName = e?.translations.find(i => i.locale === currentLanguage)?.value;
        return traslatedName ?? e.name;
      })
      this.ready = true;
    }

    render() {
      if (!this.ready) return nothing;

      const profile = app.session?.user?.profile ?? {};
      const {
        health_rating,
        health_intention
      } = profile;

      return html`
        <h4>How you rate your Overall Health</h4>
        <social-rating
          readonly
          value="${health_rating ?? 0}"
        ></social-rating>

        <h4>Your current diet</h4>
        <dl class="diet">
          <dt>Preference</dt>
          <dd>${this.diet ? html`<badge-tag class="small blue">${this.diet}</badge-tag>` : "none"}</dd>
          <dt>Allergies/intolerances</dt>
          <dd>${this.renderAllergies()}</dd>
        </dl>

        <h4>Your selected Health topics</h4>
        <ol class="health-topics">
          ${repeat(this.topics, (name) => {
            return html`<li><badge-tag>${name}</badge-tag></li>`;
          })}
        </ol>

        <h4>Your current Intention</h4>
        <p class="intention">
          ${health_intention ?? "not set"}
        </p>

        <a
          @click=${this.startHealthFlow}
          class="button outline small"
          href="/onboarding/health"
          >Update now</a
        >
      `;
    }

    startHealthFlow(e) {
      e.preventDefault();
      app.goTo("/onboarding/health", {
        force: true,
      });
    }

    renderAllergies() {
      if (!this.allergies || this.allergies.length === 0) return `none`;

      return html` ${repeat(this.allergies, (item) => {
        return html` <badge-tag class="small red">${item}</badge-tag>`;
      })}`;
    }
  }
);
